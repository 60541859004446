import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import ReactPaginate from "react-paginate";
import sorticon from "../Images/sorticon.svg";
import { superDashboard, blockUnblockCompany } from "../authApi/AuthCRUD";
import { shallowEqual, useSelector } from "react-redux";
import { Header } from "../layoutComponent/Header";
import ClockLoader from "react-spinners/ClockLoader";
import lastWorkedOn from "../Images/livedashboard/last-worked-ss.svg";
import "./super.css";
import edit from "../Images/edit.svg";
import delet from "../Images/delet.svg";
import profile from "../Images/profile.svg";
import Dropdown from "react-bootstrap/Dropdown";
import moment from "moment";
import enableIcon from "../Images/unlockIcon.png";
import disableIcon from "../Images/lockIcon.png";

const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
  <a
    href="/"
    ref={ref}
    onClick={(e) => {
      e.preventDefault();
      onClick(e);
    }}
  >
    {children}
    <span className="threedots" />
  </a>
));

export const SuperAdminDashboard = () => {
  let Authentication = useSelector((store) => store.auth.data, shallowEqual);
  const Tenant = Authentication.Tenant;
  const UserRole = Authentication.User_Role;
  const User_Id = Authentication.User_Id;
  const Tokens = Authentication.Token;

  useEffect(() => {
    getSuperDashboard();
  }, []);

  let hue = 0;
  let colors = [];
  for (let j = 0; j < 10; j++) {
    let color = "hsl(" + hue + ",80%,50%)";
    colors.push(color);
    hue += 500;
  }

  const [data, setData] = useState([]);
  let [currentpage, setCurrentPage] = useState(1);
  const [noOfPage, setNoOfPage] = useState(1);
  const [searchKey, setSearchKey] = useState("");
  let [dataPerPage, setDataPerPage] = useState(10);
  const [loading, setLoading] = useState(false);
  let [flag, setflag] = useState(false);
  const [Total_RegisterCompany, setTotal_RegisterCompany] = useState("");
  const [Total_Active_Subscription, setTotal_Active_Subscription] =
    useState("");

  const getSuperDashboard = (
    page = currentpage || 1,
    keys = "Company_Id",
    check = true,
    search = searchKey,
    pageNo = dataPerPage
  ) => {
    let data = {
      key: keys,
      ascdsc: check,
      searchname: search,
      pageNumber: page,
      PageSize: pageNo,
    };
    setLoading(true);
    superDashboard(data)
      .then((res) => {
        setLoading(false);
        console.log(res.data.Data);
        setData(res.data.Data.CompanyInfoView);
        setTotal_RegisterCompany(res.data.Data.Total_RegisterCompany);
        setTotal_Active_Subscription(res.data.Data.Total_Active_Subscription);
        setNoOfPage(res.data.TotalPages);
      })
      .catch((err) => {
        console.log(err, "error");
      });
  };

  const onSort = (key, check) => {
    if (check) setflag(false);
    else setflag(true);
    getSuperDashboard(currentpage, key, flag, searchKey, dataPerPage);
  };

  const handlePageClick = (data) => {
    let pageNumber = data.selected + 1;
    setCurrentPage(pageNumber);
    getSuperDashboard(pageNumber);
  };

  const testPage = (event) => {
    if (event.key === "Enter") {
      getSuperDashboard(currentpage, "", flag, searchKey, dataPerPage);
    }
  };

  const searchHandler = (e) => {
    if (e == "") {
      getSuperDashboard(1, "", flag, "", dataPerPage);
    } else {
      setSearchKey(e);
      getSuperDashboard(1, "", flag, e, dataPerPage);
    }
  };

  const blockUnblockCompanies = (tenantname, status) => {
    blockUnblockCompany(tenantname, status)
      .then((res) => {
        console.log(res);
        getSuperDashboard();
      })
      .catch((err) => {
        console.log(err, "error");
      });
  };

  console.log(data);
  return (
    <>
      <section className="d-flex" style={{ padding: "10px 0" }}>
        <div className="container-fluid">
          <Header title="Super Dashboard" />

          <div className="SubscriptionDetailsCards">
            <div className="row">
              <div className="col-lg-6 col-md-6 col-sm-12 mt-2">
                <div className="sb-example-3">
                  <div className="search__container">
                    <input
                      className="search-input-field"
                      type="text"
                      autoComplete="on"
                      placeholder="Search Company..."
                      onChange={(e) => {
                        searchHandler(e.target.value);
                      }}
                    />
                  </div>
                </div>
              </div>
              <div className="col-lg-3 col-md-3 col-sm-12 mt-2">
                <div className="Cards d-flex justify-content-between align-items-center">
                  <div className="subscriptionDetails">
                    <div className="monthly">Total Active Subscription</div>
                  </div>
                  <div className="border">{Total_Active_Subscription}</div>
                </div>
              </div>
              <div className="col-lg-3 col-md-3 col-sm-12 mt-2">
                <div className="Cards d-flex justify-content-between align-items-center">
                  <div className="subscriptionDetails">
                    <div className="monthly">Registered Companies</div>
                  </div>
                  <div className="border">{Total_RegisterCompany}</div>
                </div>
              </div>
            </div>
          </div>
          {/* <div className="row">
              <div className="col-lg-3 col-md-6 col-sm-12 mt-2">
                <div className="Cards d-flex justify-content-between align-items-center">
                  <div className="subscriptionDetails">
                    <div className="monthly">Monthly Subscription</div>
                    <div className="monthlyCount">0</div>
                  </div>
                  <div className="icons">
                    <div className="border"></div>
                  </div>
                </div>
              </div>
              <div className="col-lg-3 col-md-6 col-sm-12 mt-2">
                <div className="Cards d-flex justify-content-between align-items-center">
                  <div className="subscriptionDetails">
                    <div className="monthly">Annual Subscription</div>
                    <div className="monthlyCount">0</div>
                  </div>
                  <div className="icons">
                    <div className="border"></div>
                  </div>
                </div>
              </div>

              <div className="col-lg-3 col-md-6 col-sm-12 mt-2">
                <div className="Cards d-flex justify-content-between align-items-center">
                  <div className="subscriptionDetails">
                    <div className="monthly">Total Subscription</div>
                    <div className="monthlyCount">{Total_Active_Subscription}</div>
                  </div>
                  <div className="icons">
                    <div className="border"></div>
                  </div>
                </div>
              </div>
              <div className="col-lg-3 col-md-6 col-sm-12 mt-2">
                <div className="Cards d-flex justify-content-between align-items-center">
                  <div className="subscriptionDetails">
                    <div className="monthly">Registered Companies</div>
                    <div className="monthlyCount">{Total_RegisterCompany}</div>
                  </div>
                  <div className="icons">
                    <div className="border"></div>
                  </div>
                </div>
              </div>
            </div> */}

          {/* <div className="row d-flex justify-content-between align-items-center">
            <div className="col-xl-6 col-lg-6 col-md-8 col-sm-8 col-xs-12 mt-2">
              <div className="sb-example-3">
                <div className="search__container">
                  <input
                    className="search-input-field"
                    type="text"
                    autoComplete="on"
                    placeholder="Search Company..."
                    onChange={(e) => {
                      searchHandler(e.target.value);
                    }}
                  />
                </div>
              </div>
            </div>
          </div> */}

          {loading ? (
            <>
              <div className="d-flex justify-content-center text-center loaderDiv">
                <ClockLoader color="#6400B0" speedMultiplier={3} />
                <span className="loading">Loading....</span>
              </div>
            </>
          ) : (
            <>
              {!data || data === null ? (
                <>
                  <h5 className="text-secondary d-flex justify-content-center mt-5 font-poppins-medium">
                    No result available
                  </h5>
                </>
              ) : (
                <>
                  <div className="summaryDetails">
                    <div className="table-responsive">
                      <table
                        className="table"
                        style={{
                          borderBottomWidth: "0px",
                          borderBottomColor: "none",
                        }}
                      >
                        {/* <TableHead> */}
                        <thead>
                          <tr>
                            <th className="tableHead ps-4 firstHead">
                              Company Name &nbsp;
                              <button
                                onClick={() => {
                                  onSort("CompanyName", flag);
                                }}
                                type="button"
                              >
                                <img src={sorticon} alt="icon" />
                              </button>
                            </th>
                            <th className="tableHead text-center">
                              Plan &nbsp;
                              <button
                                onClick={() => {
                                  onSort("PlanName", flag);
                                }}
                                type="button"
                              >
                                <img src={sorticon} alt="icon" />
                              </button>
                            </th>
                            <th className="tableHead text-center">
                              Registered Date &nbsp;
                              <button
                                onClick={() => {
                                  onSort("CreatedDate", flag);
                                }}
                                type="button"
                              >
                                <img src={sorticon} alt="icon" />
                              </button>
                            </th>
                            <th className="tableHead text-center">
                              Expire Date &nbsp;
                              <button
                                onClick={() => {
                                  onSort("PlanExpireDate", flag);
                                }}
                                type="button"
                              >
                                <img src={sorticon} alt="icon" />
                              </button>
                            </th>
                            <th className="tableHead text-center"></th>
                          </tr>
                        </thead>
                        <tbody>
                          {data.map((res, i) => (
                            <tr
                              className=""
                              style={{ borderRadius: "30px" }}
                              key={i}
                            >
                              <td
                                className={
                                  !res.IsBlocked
                                    ? "tableCell front-radius d-flex compName"
                                    : "cellInactive front-radius d-flex compName"
                                }
                              >
                                <div className="mainDiv  d-flex">
                                  <div
                                    className="profileImg"
                                    style={{
                                      backgroundColor:
                                        colors[
                                          Math.floor(
                                            Math.random() * colors.length
                                          )
                                        ],
                                    }}
                                  >
                                    <p>
                                      {res.CompanyName.toUpperCase().charAt(0)}
                                    </p>
                                  </div>
                                  {res.CompanyName}
                                </div>
                              </td>
                              <td
                                className={
                                  !res.IsBlocked
                                    ? "tableCell text-center"
                                    : "cellInactive text-center"
                                }
                              >
                                <div className="mainDiv">
                                  <div>{res.PlanName}</div>
                                </div>
                              </td>
                              <td
                                className={
                                  !res.IsBlocked
                                    ? "tableCell text-center"
                                    : "cellInactive text-center"
                                }
                              >
                                <div className="mainDiv">
                                  {res.CreatedDate !== null
                                    ? moment(res.CreatedDate).format(
                                        "DD.MM.YYYY"
                                      )
                                    : "-"}
                                </div>
                              </td>
                              <td
                                className={
                                  !res.IsBlocked
                                    ? "tableCell text-center"
                                    : "cellInactive text-center"
                                }
                              >
                                <div className="mainDiv">
                                  {res.PlanExpireDate !== null
                                    ? moment(res.PlanExpireDate).format(
                                        "DD.MM.YYYY"
                                      )
                                    : "-"}
                                </div>
                              </td>
                              <td
                                className={
                                  !res.IsBlocked
                                    ? "tableCell back-radius"
                                    : "cellInactive back-radius"
                                }
                              >
                                <Dropdown>
                                  <Dropdown.Toggle as={CustomToggle} />
                                  <Dropdown.Menu size="sm">
                                    <Dropdown.Item
                                      className="drpdwnitem"
                                      style={{
                                        color: "red",
                                        opacity: "0.9",
                                        fontSize: "12px",
                                        fontWeight: "400",
                                        fontFamily: "Poppins",
                                        fontStyle: "normal",
                                      }}
                                    >
                                      {res.IsBlocked ? (
                                        <div
                                          className="d-flex justify-content-center align-items-center"
                                          id="1"
                                          style={{
                                            color: "green",
                                          }}
                                          onClick={() =>
                                            blockUnblockCompanies(
                                              res.CompanyName,
                                              false
                                            )
                                          }
                                        >
                                          <img
                                            src={enableIcon}
                                            alt="icon"
                                            width={15}
                                            height={15}
                                          />
                                          <div> &nbsp; UnBlock</div>
                                        </div>
                                      ) : (
                                        <div
                                          className="d-flex justify-content-center align-items-center"
                                          id="2"
                                          style={{
                                            color: "red",
                                          }}
                                          onClick={() =>
                                            blockUnblockCompanies(
                                              res.CompanyName,
                                              true
                                            )
                                          }
                                        >
                                          <img
                                            src={disableIcon}
                                            alt="icon"
                                            width={15}
                                            height={15}
                                          />
                                          <div> &nbsp; Block</div>
                                        </div>
                                      )}
                                    </Dropdown.Item>
                                  </Dropdown.Menu>
                                </Dropdown>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </>
              )}
            </>
          )}
          {(noOfPage == 1 && (!data || data.length <= 10)) || noOfPage == 0 ? (
            ""
          ) : (
            <div className="paginations d-flex justify-content-between align-items-center mt-2">
              <div className="d-flex justify-content-center align-items-center">
                <p className="lableRepo p-0 m-0">Rows per page : </p>
                <input
                  id="input-control"
                  name="policy"
                  type="text"
                  className="form-control w-20 text-center team-select"
                  autoComplete="off"
                  style={{
                    marginLeft: "4px",
                    height: "30px",
                    width: "100px",
                  }}
                  defaultValue={dataPerPage}
                  onChange={(e) => setDataPerPage(e.target.value)}
                  onKeyDown={testPage}
                />
              </div>
              <ReactPaginate
                previousLabel={"<"}
                nextLabel={">"}
                pageCount={noOfPage}
                onPageChange={handlePageClick}
                containerClassName={"pagination justify-content-center"}
                pageClassName={"page-item"}
                pageLinkClassName={"page-link"}
                previousClassName={"page-item"}
                previousLinkClassName={"page-link"}
                nextClassName={"page-item"}
                nextLinkClassName={"page-link"}
                breakClassName={"page-item"}
                breakLinkClassName={"page-link"}
                activeClassName={"active"}
              />
            </div>
          )}
        </div>
      </section>
    </>
  );
};
