import React, { useRef, useState, useEffect } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import ReactQuill from "react-quill";
import "./Email-template.css";
import "react-quill/dist/quill.snow.css";
import {
  EditEmailTemplateList,
  GetEmailTemplateDetail,
} from "../authApi/AuthCRUD";
import backArrow from "../Images/backArrow.svg";
import { Header } from "../layoutComponent/Header";
import { ToastContainer, toast } from "react-toastify";
export default function EditEmailTemplate() {
  //   const history = useHistory();
  const navigate = useNavigate();
  const [msg, setMsg] = useState(false);
  const [TemplateData, setTemplateData] = useState({
    name: "",
    template_variables: "",
  });
  const [loading1, setLoading1] = useState(false);
  const [loading, setLoading] = useState(false);
  const [disable, setDisable] = useState(false);
  const [Status, setStatus] = useState("");
  const ref = useRef(null);

  const [value, setValue] = useState("");

  const Editorvalue = (value) => {
    setValue(value);
  };
  const { search } = useLocation();
  const list_id = new URLSearchParams(search).get("template_id");
  let element = document.getElementsByClassName(
    "btn btn-default btn edit-template"
  );

  const modules = {
    toolbar: [
      ["bold", "italic", "underline", "strike", "link"],
      ["blockquote", "code-block"],
      [{ list: "ordered" }, { list: "bullet" }],
      [{ header: [1, 2, 3, 4, 5, 6, false] }],
      [
        { align: "" },
        { align: "center" },
        { align: "right" },
        { align: "justify" },
      ],
    ],
  };

  useEffect(() => {
    GetEmailTemplateDetail(list_id).then((res) => {
      setLoading1(true);
      setFormvalues({
        bcc_email: res.data.Bcc_email ? res.data.Bcc_email : "",
        cc_email: res.data.Cc_email ? res.data.Cc_email : "",
        subject: res.data.Subject,
      });
      setTemplateData({
        name: res.data.Name,
        template_variables: res.data.Template_variables,
      });
      setValue(res.data.Body);
    });
  }, []);

  const [formerrors, setFormErrors] = useState({
    bcc_email: "",
    cc_email: "",
    subject: "",
    body: "",
  });
  const [formvalues, setFormvalues] = useState({
    cc_email: "",
    bcc_email: "",
    subject: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormvalues({ ...formvalues, [name]: value });
  };

  const handleSubmit = (e) => {
    setMsg(true);
    e.preventDefault();
    if (validate(formvalues, value)) {
      setLoading(true);
      setDisable(true);
      EditEmailTemplateList(
        list_id,
        formvalues.cc_email,
        formvalues.bcc_email,
        formvalues.subject,
        value
      )
        .then((res) => {
          setLoading(false);
          setDisable(false);
          toast.success("Email template updated successfully", {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: false,
            progress: undefined,
          });
          navigate("/email-template");
        })
        .catch((err) => {
          setLoading(false);
          setDisable(false);
          if (err.response.status === 403) {
            // toast.error(err.response.data.message, {
            //   position: "top-right",
            //   autoClose: 3000,
            //   hideProgressBar: false,
            //   closeOnClick: true,
            //   pauseOnHover: true,
            //   draggable: false,
            //   progress: undefined,
            // });
          } else {
            // toast.error("There is some error try again", {
            //   position: "top-right",
            //   autoClose: 5000,
            //   hideProgressBar: false,
            //   closeOnClick: true,
            //   pauseOnHover: true,
            //   draggable: false,
            //   progress: undefined,
            // });
          }
        });
    }
  };
  const validate = (values, datavalue) => {
    const reg = /\b[^\d\W]+\b/g;
    const regsub = /^[a-zA-Z ]*$/;
    const regex = /^[a-zA-Z0-9]+@(?:[a-zA-Z0-9]+\.)+[A-Za-z]+$/;
    const errors = {};
    // if (!values.cc_email) {
    //   errors.cc_email = "Email field is required*";
    // }      
    //  else if (!regex.test(values.cc_email)) {
    //   errors.cc_email = "Invalid Email";
    // }
    if (!values.subject) {
      errors.subject = "Subject field is required*";
    }

    // if (values.bcc_email) {
    //   if (!regex.test(values.bcc_email)) {
    //     errors.bcc_email = "Invalid Email";
    //   }
    // }

    if (datavalue == "<p><br></p>") {
      errors.body = "body field is required*";
    } else if (datavalue == "<h1><br></h1>") {
      errors.body = "body field is required*";
    } else if (datavalue == null) {
      errors.body = "body field is required*";
    }

    if (Object.keys(errors).length) {
      setFormErrors(errors);
      return false;
    } else {
      setFormErrors({
        bcc_email: "",
        cc_email: "",
        subject: "",
        body: "",
      });
      return true;
    }
  };
  return (
    <>
      <div className="container-fluid">
        <Header title="Email Template" />
        <div className="text-start mt-3 mb-3">
          <div
            className="d-flex align-items-center"
            role="button"
            onClick={() => navigate(-1)}
          >
            <i>
              <img src={backArrow} alt="back-Arrow" />
            </i>
            <span className="font-poppins-regular backBtn">
              &nbsp;&nbsp;&nbsp;Back
            </span>
          </div>
        </div>

        <div id="main-card-email" className="card">
          <form onSubmit={handleSubmit}>
            {/* card header start */}
            <div
              id="edit-email-card-heading"
              className="card-header p-0 border-0"
            >
              <div className="d-flex justify-content-between">
                <div className="d-flex">
                  <h5 className="update-email-heading mt-2">Update Template</h5>
                </div>
                <div className="d-flex">
                  <div ref={ref}>
                    <button
                      disabled={disable}
                      type="submit"
                      className="btn buttonDefault"
                    >
                      {!loading && (
                        <span className="indicator-label ">Save</span>
                      )}
                      {loading && (
                        <span
                          className="indicator-progress"
                          style={{ display: "block" }}
                        >
                          Please wait...
                          <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                        </span>
                      )}
                    </button>
                  </div>
                </div>
              </div>
            </div>
            {/* card header start */}
            {/* card body start */}
            <div id="card-email-body-part pt-2" className="card-body">
              {/* {Status=="Email template updated successfully"? <span className="font-roboto-bold mb-3" style={{color:"green",fontSize:"15px",display:"block"}}>{Status}</span>:null} */}
              <div className="row ">
                <div className="col-sm-12 col-xs-12 col-lg-6 col-xl-8 col-md-6">
                  <label className="labels mb-2">Template Type</label>
                  <input
                    value={TemplateData.name}
                    id="template_type"
                    name="name"
                    type="text"
                    className="form-control"
                    autoComplete="off"
                    readOnly
                  />
                </div>
                <div className="col-sm-0 col-xs-0 col-lg-6 col-xl-4 col-md-6">
                  <div className="text-left available-template">
                    <h5 className="update-email-heading">
                      Available Template Variables
                    </h5>
                    <p style={{ fontFamily: "Mont-Regular" }}>
                      {TemplateData.template_variables}
                    </p>
                  </div>
                </div>
              </div>
              <div className="row mt-3">
                <div className="col-sm-12 col-xs-12 col-lg-6 col-xl-8 col-md-6">
                  <label className="labels mb-2">CC Email</label>
                  <input
                    id="input-template"
                    value={formvalues.cc_email}
                    onChange={handleChange}
                    name="cc_email"
                    type="text"
                    className="form-control"
                    autoComplete="off"
                    placeholder="CC Email"
                  />
                  <div className="mt-1">
                    <span
                      className="font-roboto-regular"
                      style={{ color: "red", fontSize: "15px" }}
                    >
                      {" "}
                      {formerrors.cc_email}{" "}
                    </span>
                  </div>
                </div>
                <div className="col-sm-0 col-xs-0 col-lg-6 col-xl-4 col-md-6"></div>
              </div>
              <div className="row mt-3">
                <div className="col-sm-12 col-xs-12 col-lg-6 col-xl-8 col-md-6">
                  <label className="labels  mb-2">BCC Email</label>
                  <input
                    id="input-template"
                    value={formvalues.bcc_email}
                    onChange={handleChange}
                    name="bcc_email"
                    type="text"
                    className="form-control"
                    autoComplete="off"
                    placeholder="BCC Email"
                  />
                  <div className="mt-1">
                    <span
                      className="font-roboto-regular"
                      style={{ color: "red", fontSize: "15px" }}
                    >
                      {" "}
                      {formerrors.bcc_email}{" "}
                    </span>
                  </div>
                </div>
                <div className="col-sm-0 col-xs-0 col-lg-6 col-xl-4 col-md-6"></div>
              </div>
              <div className="row mt-3">
                <div className="col-sm-12 col-xs-12 col-lg-6 col-xl-8 col-md-6">
                  <label className="labels mb-2">
                    Subject<span className="text-danger">*</span>
                  </label>
                  <input
                    id="input-template"
                    value={formvalues.subject}
                    onChange={handleChange}
                    name="subject"
                    type="text"
                    className="form-control"
                    autoComplete="off"
                    placeholder="Subject"
                  />
                  <div className="mt-2 ps-3">
                    <span
                      className="mont-font-regular"
                      style={{ color: "red", fontSize: "13px" }}
                    >
                      {" "}
                      {formerrors.subject}{" "}
                    </span>
                  </div>
                </div>
                <div className="col-sm-0 col-xs-0 col-lg-6 col-xl-4 col-md-6"></div>
              </div>
              <div className="row mt-3">
                <div className="col-sm-12 col-xs-12 col-lg-6 col-xl-8 col-md-6">
                  <label className="labels mb-2">
                    Body<span className="text-danger">*</span>
                  </label>
                  <div className="editor-div ">
                    <div id="editor-card-body">
                      <ReactQuill
                        value={value}
                        onChange={Editorvalue}
                        name="body"
                        modules={modules}
                        theme="snow"
                        style={{background: '#ffffff'}}
                      />
                      <div className="mb-4">
                        <span
                          className="mont-font-regular"
                          style={{ color: "red", fontSize: "13px" }}
                        >
                          {" "}
                          {formerrors.body}{" "}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-sm-0 col-xs-0 col-lg-6 col-xl-4 col-md-6"></div>
              </div>
            </div>
            {/* card body end  */}
          </form>
        </div>
      </div>
      {msg ? (
        <>
          <ToastContainer />
        </>
      ) : null}
    </>
  );
}
