import React, { useState, useEffect } from "react";
import "../css/style.css";
import Select from "react-select";
import { postPlan, checkJWTToken, getPermissions } from "../authApi/AuthCRUD";
import { Header } from "../layoutComponent/Header";
import backArrow from "../Images/livedashboard/back-arrow.svg";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useDispatch, shallowEqual, useSelector } from "react-redux";
import { authUserLogout } from "../reduxAuthentication/actions";
import Checkbox from "@mui/material/Checkbox";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import $ from "jquery";

export default function SuperAdminCreateSupscription() {
  const [arrayData, setArrayData] = useState([]);
  const [arrayData1, setArrayData1] = useState([]);
  let Authentication = useSelector((store) => store.auth.data, shallowEqual);
  const Tenant = Authentication.Tenant;
  const UserRole = Authentication.User_Role;
  const UserToken = Authentication.Token;
  const [loading, setLoading] = useState(false);

  let navigate = useNavigate();
  let dispatch = useDispatch();

  useEffect(() => {
    // Auto logout user when token is expire
    if (!checkJWTToken(UserToken)) {
      localStorage.clear();
      dispatch(authUserLogout());
      navigate("/");
    } else {
      getPermissionsPlans();
    }
  }, []);

  const [permissions, setPermissions] = useState([]);
  const [Plan_Name, setPlan_Name] = useState("");
  const [Plan_Duration, setPlan_Duration] = useState();
  const [Price, setPrice] = useState();
  const [Discount, setDiscount] = useState();
  const [GST, setGST] = useState();
  const [Users, setUsers] = useState();
  let arr = [];
  const [checked, setChecked] = useState([]);

  const getPermissionsPlans = () => {
    getPermissions()
      .then((res) => {
        setPermissions(res.data.Data);
        console.log(res.data.Data);
      })
      .catch((err) => {
        console.log(err, "error");
      });
  };

  const handleCheckbox = () => {
    var checked = [];
    var unchecked = [];
    $("input[type='checkbox']:checked").each(function () {
      checked[checked.length] = this.value;
    });
    $("input[type='checkbox']:not(:checked)").each(function () {
      unchecked[unchecked.length] = this.value;
    });
    setChecked(checked);
  };

  const [formerrors, setFormErrors] = useState({
    Plan_Name: "",
    Plan_Duration: "",
    Price: "",
    Discount: "",
    GST: "",
    Users: "",
  });
  const validate = (values) => {
    const errors = {};
    let re;

    let gst = !values.GST ? 0 : values.GST;
    let discount = !values.Discount ? 0 : values.Discount;

    if (!values.PlanName) {
      errors.Plan_Name = "Plan name is required*";
    }
    if (!values.PlanDuration) {
      errors.Plan_Duration = "Plan duration is required*";
    } else if (!/^[0-9]+$/.test(Number(values.PlanDuration))) {
      errors.Plan_Duration = "Enter valid Plan duration";
    }

    if (!values.Price) {
      errors.Price = "Price is required*";
    } else if (!/^[0-9.]*$/.test(Number(values.Price))) {
      errors.Price = "Enter valid Price value";
    }

    if (!values.Users) {
      errors.Users = "Users is required*";
    } else if (!/^[0-9]+$/.test(Number(values.Users))) {
      errors.Users = "Enter valid User value";
    }

    if (!/^[0-9.]*$/.test(Number(discount))) {
      errors.Discount = "Enter valid Price value";
    }
    if (!/^[0-9.]*$/.test(Number(gst))) {
      errors.GST = "Enter valid Price value";
    }

    if (Object.keys(errors).length) {
      setFormErrors(errors);
      return false;
    } else {
      setFormErrors({
        Plan_Name: "",
        Plan_Duration: "",
        Price: "",
        Discount: "",
        GST: "",
        Users: "",
      });
      return true;
    }
  };

  const handleChange = (event) => {
    var updatedList = [...checked];
    if (event.target.checked) {
      updatedList = [...checked, event.target.value];
    } else {
      updatedList.splice(checked.indexOf(event.target.value), 1);
    }
    setChecked(updatedList);
  };

  const postData = (e) => {
    e.preventDefault();
    let data = {
      PlanName: Plan_Name,
      PlanDuration: Plan_Duration,
      Price: Price,
      Discount: Discount,
      GST: GST,
      Users: Users,
      Permissions: checked,
    };
    if (validate(data)) {
      postPlan(data)
        .then((res) => {
          navigate("/superadminsubscription");
        })
        .catch((err) => {
          console.log(err, "error");
        });
    }
  };

  function validateName(name) {
    const re = /^[0-9.]*$/; // regular expression to match letters and spaces
    return re.test(name);
  }

  $("#number").keypress(function (event) {
    var character = String.fromCharCode(event.keyCode);
    return isValid(character);
  });

  function isValid(str) {
    return !/[~`!@#$%\^&*()+=\-\[\]\\';,/{}|\\":<>\?]/g.test(str);
  }

  return (
    <section className="d-flex">
      <div className="container-fluid">
        <Header title="Create Plan" />
        <div className="text-start mt-3 mb-3">
          <div
            className="d-flex align-items-center"
            role="button"
            onClick={() => navigate(-1)}
          >
            <i>
              <img src={backArrow} alt="back-Arrow" />
            </i>
            <span className="font-poppins-regular backBtn">
              &nbsp;&nbsp;&nbsp;Back
            </span>
          </div>
        </div>

        <section className="addUsers mb-5">
          <div className="container">
            <form>
              <div className="titleHeader">Subscription plan detail</div>
              <div className="EditPlanForm">
                <div className="row">
                  <div className="col-lg-6 col-md-6 col-sm-12">
                    <div className="form-group mt-3">
                      <label className="w-100 mb-2 lableRepo label-field">
                        Plan Name<span className="text-danger">*</span>
                        <input
                          type="text"
                          style={{ width: "100%" }}
                          className="form-control bdr-radius team-select"
                          name="Title"
                          placeholder="Plan Name"
                          onChange={(e) => {
                            setPlan_Name(e.target.value);
                            setFormErrors({ ...formerrors, Plan_Name: "" });
                          }}
                        />
                      </label>
                    </div>
                    <div className="text-alert font-poppins-medium">
                      <span role="alert" className="formErr">
                        {formerrors.Plan_Name}
                      </span>
                    </div>
                  </div>
                  <div className="col-lg-6 col-md-6 col-sm-12">
                    <div className="form-group mt-3">
                      <label className="w-100 mb-2 lableRepo label-field">
                        Plan Duration(in number of months)
                        <span className="text-danger">*</span>
                        <input
                          type="number"
                          id="number"
                          min={0}
                          style={{ width: "100%" }}
                          className="form-control bdr-radius team-select"
                          name="Title"
                          placeholder="Plan Duration in numbers"
                          // onkeydown="if(event.key==='.'){event.preventDefault();}"
                          // oninput="event.target.value = event.target.value.replace(/[^0-9]*/g,'');"
                          // tabIndex="0"
                          onChange={(e) => {
                            setPlan_Duration(e.target.value);
                            setFormErrors({ ...formerrors, Plan_Duration: "" });
                            validateName();
                          }}
                        />
                      </label>
                    </div>
                    <div className="text-alert font-poppins-medium">
                      <span role="alert" className="formErr">
                        {formerrors.Plan_Duration}
                      </span>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-lg-6 col-md-6 col-sm-12">
                    <div className="form-group mt-3">
                      <label className="w-100 mb-2 lableRepo label-field">
                        Price(in $)<span className="text-danger">*</span>
                        <input
                          type="number"
                          id="number"
                          min={0}
                          style={{ width: "100%" }}
                          className="form-control bdr-radius team-select"
                          name="Title"
                          placeholder="Price in numbers"
                          onChange={(e) => {
                            setPrice(e.target.value);
                            setFormErrors({ ...formerrors, Price: "" });
                          }}
                        />
                      </label>
                    </div>
                    <div className="text-alert font-poppins-medium">
                      <span role="alert" className="formErr">
                        {formerrors.Price}
                      </span>
                    </div>
                  </div>
                  <div className="col-lg-6 col-md-6 col-sm-12">
                    <div className="form-group mt-3">
                      <label className="w-100 mb-2 lableRepo label-field">
                        Users(in numbers)<span className="text-danger">*</span>
                        <input
                          type="number"
                          id="number"
                          min={0}
                          style={{ width: "100%" }}
                          className="form-control bdr-radius team-select"
                          name="Title"
                          placeholder="Users in numbers"
                          onChange={(e) => {
                            setUsers(e.target.value);
                            setFormErrors({ ...formerrors, Users: "" });
                          }}
                        />
                      </label>
                    </div>
                    <div className="text-alert font-poppins-medium">
                      <span role="alert" className="formErr">
                        {formerrors.Users}
                      </span>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-lg-6 col-md-6 col-sm-12">
                    <div className="form-group mt-3">
                      <label className="w-100 mb-2 lableRepo label-field">
                        Discount(in %)
                        <input
                          type="number"
                          id="number"
                          min={0}
                          style={{ width: "100%" }}
                          className="form-control bdr-radius team-select"
                          name="Title"
                          defaultValue={0}
                          placeholder="Discount"
                          onChange={(e) => {
                            setDiscount(e.target.value);
                            setFormErrors({ ...formerrors, Discount: "" });
                          }}
                        />
                      </label>
                    </div>
                    <div className="text-alert font-poppins-medium">
                      <span role="alert" className="formErr">
                        {formerrors.Discount}
                      </span>
                    </div>
                  </div>
                  <div className="col-lg-6 col-md-6 col-sm-12">
                    <div className="form-group mt-3">
                      <label className="w-100 mb-2 lableRepo label-field">
                        GST(in %)
                        <input
                          type="number"
                          id="number"
                          min={0}
                          style={{ width: "100%" }}
                          className="form-control bdr-radius team-select"
                          name="Title"
                          defaultValue={0}
                          placeholder="GST"
                          onChange={(e) => {
                            setGST(e.target.value);
                            setFormErrors({ ...formerrors, GST: "" });
                          }}
                        />
                      </label>
                    </div>
                    <div className="text-alert font-poppins-medium">
                      <span role="alert" className="formErr">
                        {formerrors.GST}
                      </span>
                    </div>
                  </div>
                </div>

                <div className="row">
                  <div className="col-lg-12 col-md-12 col-sm-12">
                    <div className="mt-4">
                      <label className="mb-2">Permissions</label>
                    </div>

                    <div className="row">
                      {permissions &&
                        permissions.map((item) => {
                          return (
                            <React.Fragment key={item.PermissionId}>
                              <div className="col-lg-4">
                                <FormControlLabel
                                  control={
                                    <Checkbox
                                      value={item.PermissionId}
                                      onChange={handleChange}
                                    />
                                  }
                                  label={item.DisplayName}
                                />
                              </div>
                            </React.Fragment>
                          );
                        })}
                    </div>
                  </div>
                </div>
              </div>

              <div className="mt-5 d-flex justify-content-center align-items-center">
                <button
                  type="submit"
                  className="btn buttonDefault me-2"
                  disabled={loading}
                  onClick={postData}
                >
                  {!loading && <span>Save</span>}
                  {loading && (
                    <span
                      className="indicator-progress"
                      style={{ display: "block" }}
                    >
                      Save
                      <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                    </span>
                  )}
                </button>
                <Link to="/superadminsubscription" style={{ color: "#fff" }}>
                  <button
                    type="button"
                    className="btn buttonDefault me-2"
                    style={{
                      background: "#8A899C",
                    }}
                  >
                    Cancel
                  </button>
                </Link>
              </div>
            </form>
          </div>
        </section>
      </div>
    </section>
  );
}
