import React, { useState, useEffect } from "react";
import "../css/style.css";
import Select from "react-select";
import {
  subscriptonPlansById,
  checkJWTToken,
  putPlan,
  subscriptonEnable,
  subscriptonDisable,
} from "../authApi/AuthCRUD";
import { Header } from "../layoutComponent/Header";
import backArrow from "../Images/livedashboard/back-arrow.svg";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useDispatch, shallowEqual, useSelector } from "react-redux";
import { authUserLogout } from "../reduxAuthentication/actions";
import Radio from "@mui/material/Radio";
import Checkbox from "@mui/material/Checkbox";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import $ from "jquery";

export default function SuperAdminEditSupscription() {
  const [arrayData, setArrayData] = useState([]);
  const [arrayData1, setArrayData1] = useState([]);
  let Authentication = useSelector((store) => store.auth.data, shallowEqual);
  const Tenant = Authentication.Tenant;
  const UserRole = Authentication.User_Role;
  const UserToken = Authentication.Token;
  const [loading, setLoading] = useState(false);

  let navigate = useNavigate();
  let dispatch = useDispatch();

  useEffect(() => {
    // Auto logout user when token is expire
    if (!checkJWTToken(UserToken)) {
      localStorage.clear();
      dispatch(authUserLogout());
      navigate("/");
    } else {
      specificPlanId();
    }
  }, []);

  const { search } = useLocation();
  let planId = new URLSearchParams(search).get("planid");
  const [permissionsList, setPermissionsList] = useState([]);
  const [Plan_Name, setPlan_Name] = useState("");
  const [Plan_Duration, setPlan_Duration] = useState();
  const [Price, setPrice] = useState();
  const [Discount, setDiscount] = useState();
  const [GST, setGST] = useState();
  const [Users, setUsers] = useState();
  let arr = [];
  const [checked, setChecked] = useState([]);

  const specificPlanId = () => {
    subscriptonPlansById(planId)
      .then((res) => {
        setPermissionsList(res.data.Data.planPermissionViews);
        setPlan_Name(res.data.Data.Plan_Name);
        setPrice(res.data.Data.Price);
        setDiscount(res.data.Data.Discount);
        setGST(res.data.Data.GST);
        setPlan_Duration(res.data.Data.Plan_Duration);
        setUsers(res.data.Data.Users);
      })
      .catch((err) => {
        console.log(err, "error");
      });
  };

  const handleCheckbox = () => {
    var checked = [];
    var unchecked = [];
    $("input[type='checkbox']:checked").each(function () {
      checked[checked.length] = this.value;
    });
    $("input[type='checkbox']:not(:checked)").each(function () {
      unchecked[unchecked.length] = this.value;
    });
    setChecked(checked);
  };

  const [formerrors, setFormErrors] = useState({
    Plan_Name: "",
    Plan_Duration: "",
    Price: "",
    Users: "",
  });
  const validate = (values) => {
    const errors = {};
    let re;

    let gst = !values.GST ? 0 : values.GST;
    let discount = !values.Discount ? 0 : values.Discount;

    if (!values.PlanName) {
      errors.Plan_Name = "Plan name is required*";
    }
    if (!values.PlanDuration) {
      errors.Plan_Duration = "Plan duration is required*";
    } else if (!/^[0-9]+$/.test(Number(values.PlanDuration))) {
      errors.Plan_Duration = "Enter valid Plan duration";
    }

    if (!values.Price) {
      errors.Price = "Price is required*";
    } else if (!/^[0-9.]*$/.test(Number(values.Price))) {
      errors.Price = "Enter valid Price value";
    }

    if (!values.Users) {
      errors.Users = "Users is required*";
    } else if (!/^[0-9]+$/.test(Number(values.Users))) {
      errors.Users = "Enter valid User value";
    }

    if (!/^[0-9.]*$/.test(Number(discount))) {
      errors.Discount = "Enter valid Price value";
    }
    if (!/^[0-9.]*$/.test(Number(gst))) {
      errors.GST = "Enter valid Price value";
    }
    if (Object.keys(errors).length) {
      setFormErrors(errors);
      return false;
    } else {
      setFormErrors({
        Plan_Name: "",
        Plan_Duration: "",
        Price: "",
        Users: "",
      });
      return true;
    }
  };

  const postData = () => {
    let filterList = permissionsList.filter((data) => {
      if (data.Status) {
        return data.Permission_Id;
      }
    });
    var filteredPermissionList = filterList.map(function (el) {
      return el.Permission_Id;
    });

    let data = {
      Id: parseInt(planId),
      PlanName: Plan_Name,
      PlanDuration: Plan_Duration,
      Price: Price,
      Discount: Discount,
      GST: GST,
      Users: Users,
      Permissions: checked.length == 0 ? filteredPermissionList : checked,
    };
    if (validate(data)) {
      putPlan(data)
        .then((res) => {
          navigate("/superadminsubscription");
        })
        .catch((err) => {
          console.log(err, "error");
        });
    }
  };

  return (
    <section className="d-flex">
      <div className="container-fluid">
        <Header title="Edit Plan" />
        <div className="text-start mt-3 mb-3">
          <div
            className="d-flex align-items-center"
            role="button"
            onClick={() => navigate(-1)}
          >
            <i>
              <img src={backArrow} alt="back-Arrow" />
            </i>
            <span className="font-poppins-regular backBtn">
              &nbsp;&nbsp;&nbsp;Back
            </span>
          </div>
        </div>

        <section className="addUsers mb-5">
          <form>
            <div
              className="titleHeader"
              style={{
                fontFamily: "Poppins",
                fontStyle: "normal",
                fontWeight: "500",
                fontSize: "16px",
                lineHeight: "48px",
                color: "#2A2A2E",
              }}
            >
              Subscription plan detail
            </div>

            <div className="EditPlanForm">
              <div className="row">
                <div className="col-lg-6 col-md-6 col-sm-12">
                  <div className="form-group mt-3">
                    <label className="w-100 mb-2 lableRepo label-field">
                      Plan Name <span className="text-danger">*</span>
                      <input
                        type="text"
                        style={{ width: "100%" }}
                        defaultValue={Plan_Name}
                        className="form-control bdr-radius team-select"
                        name="Title"
                        placeholder="Plan Name"
                        onKeyUp={(e) => {
                          setPlan_Name(
                            e.target.value === "" ? Plan_Name : e.target.value
                          );
                          setFormErrors({ ...formerrors, Plan_Name: "" });
                        }}
                      />
                    </label>
                  </div>
                  <div className="text-alert font-poppins-medium">
                    <span role="alert" className="formErr">
                      {formerrors.Plan_Name}
                    </span>
                  </div>
                </div>
                <div className="col-lg-6 col-md-6 col-sm-12">
                  <div className="form-group mt-3">
                    <label className="w-100 mb-2 lableRepo label-field">
                      Plan Duration(in number of months){" "}
                      <span className="text-danger">*</span>
                      <input
                        type="number"
                        min={0}
                        style={{ width: "100%" }}
                        defaultValue={Plan_Duration}
                        className="form-control bdr-radius team-select"
                        name="Title"
                        placeholder="Plan Duration in numbers"
                        onKeyUp={(e) => {
                          setPlan_Duration(
                            e.target.value === ""
                              ? Plan_Duration
                              : e.target.value
                          );
                          setFormErrors({ ...formerrors, Plan_Duration: "" });
                        }}
                      />
                    </label>
                  </div>
                  <div className="text-alert font-poppins-medium">
                    <span role="alert" className="formErr">
                      {formerrors.Plan_Duration}
                    </span>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-lg-6 col-md-6 col-sm-12">
                  <div className="form-group mt-3">
                    <label className="w-100 mb-2 lableRepo label-field">
                      Price <span className="text-danger">*</span>
                      <input
                        type="number"
                        min={0}
                        style={{ width: "100%" }}
                        defaultValue={Price}
                        className="form-control bdr-radius team-select"
                        name="Title"
                        placeholder="Price"
                        onKeyUp={(e) => {
                          setPrice(
                            e.target.value === "" ? Price : e.target.value
                          );
                          setFormErrors({ ...formerrors, Price: "" });
                        }}
                      />
                    </label>
                  </div>
                  <div className="text-alert font-poppins-medium">
                    <span role="alert" className="formErr">
                      {formerrors.Price}
                    </span>
                  </div>
                </div>
                <div className="col-lg-6 col-md-6 col-sm-12">
                  <div className="form-group mt-3">
                    <label className="w-100 mb-2 lableRepo label-field">
                      Users(in numbers) <span className="text-danger">*</span>
                      <input
                        type="number"
                        min={0}
                        style={{ width: "100%" }}
                        defaultValue={Users}
                        className="form-control bdr-radius team-select"
                        name="Title"
                        placeholder="Users in numbers"
                        onKeyUp={(e) => {
                          setUsers(
                            e.target.value === "" ? Users : e.target.value
                          );
                          setFormErrors({ ...formerrors, Users: "" });
                        }}
                      />
                    </label>
                  </div>
                  <div className="text-alert font-poppins-medium">
                    <span role="alert" className="formErr">
                      {formerrors.Users}
                    </span>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-lg-6 col-md-6 col-sm-12">
                  <div className="form-group mt-3">
                    <label className="w-100 mb-2 lableRepo label-field">
                      Discount(in %)
                      <input
                        type="number"
                        style={{ width: "100%" }}
                        defaultValue={Discount}
                        className="form-control bdr-radius team-select"
                        name="Title"
                        min={0}
                        placeholder="Discount"
                        onKeyUp={(e) => {
                          setDiscount(
                            e.target.value === "" ? Discount : e.target.value
                          );
                        }}
                      />
                    </label>
                  </div>
                </div>
                <div className="col-lg-6 col-md-6 col-sm-12">
                  <div className="form-group mt-3">
                    <label className="w-100 mb-2 lableRepo label-field">
                      GST(in %)
                      <input
                        type="number"
                        style={{ width: "100%" }}
                        defaultValue={GST}
                        className="form-control bdr-radius team-select"
                        name="Title"
                        min={0}
                        placeholder="GST"
                        onKeyUp={(e) => {
                          setGST(e.target.value === "" ? GST : e.target.value);
                        }}
                      />
                    </label>
                  </div>
                </div>
              </div>

              <div className="row mb-5">
                <div className="col-lg-12 col-md-12 col-sm-12">
                  <div className="mt-4">
                    <label className="w-100 mb-2 lableRepo label-field">
                      Permissions
                    </label>
                  </div>

                  <div className="row">
                    {permissionsList &&
                      permissionsList.map((item) => {
                        return (
                          <React.Fragment key={item.Permission_Id}>
                            <div className="col-lg-4">
                              <FormControlLabel
                                control={
                                  <Checkbox
                                    size="small"
                                    defaultChecked={
                                      item.Status &&
                                      arr.push(
                                        item.Status === true
                                          ? item.Display_Name
                                          : null
                                      )
                                    }
                                    value={item.Permission_Id}
                                    onChange={handleCheckbox}
                                  />
                                }
                                label={item.Display_Name}
                              />
                            </div>
                          </React.Fragment>
                        );
                      })}
                  </div>
                </div>
              </div>
            </div>
          </form>

          <div className="mt-5 d-flex justify-content-center align-items-center">
            <button
              type="button"
              className="btn buttonDefault me-2"
              disabled={loading}
              onClick={postData}
            >
              {!loading && <span>Save</span>}
              {loading && (
                <span
                  className="indicator-progress"
                  style={{ display: "block" }}
                >
                  Save
                  <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                </span>
              )}
            </button>
            <Link to="/superadminsubscription" style={{ color: "#fff" }}>
              <button
                type="button"
                className="btn buttonDefault me-2"
                style={{
                  background: "#8A899C",
                }}
              >
                Cancel
              </button>
            </Link>
          </div>
        </section>
      </div>
    </section>
  );
}
