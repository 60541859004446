import React, { useState } from "react";
import "../css/style.css";
import { Link, useNavigate } from "react-router-dom";
import styled from "styled-components";
import { Header } from "../layoutComponent/Header";
import FormGroup from "@mui/material/FormGroup";
import backArrow from "../Images/livedashboard/back-arrow.svg";

import { uploadDesktopApplication } from "../authApi/AuthCRUD";

export default function UploadApp() {
  const styles = {
    select: {
      fontFamily: "Poppins",
      fontStyle: "normal",
      fontWeight: "400",
      fontSize: "13px",
      lineHeight: "20px",
      color: "#B6B6B6",
    },
    cancelBtn: {
      border: "none",
      marginRight: "5px",
      borderRadius: "60px",
      background: "#616773",
      color: "#fff",
      padding: "10px 100px",
    },
    saveBtn: {
      border: "none",
      borderRadius: "30px",
      padding: "10px 100px",
      background: "#6414b5",
      color: "#fff",
    },
  };
  const Label = styled.label`
    font-family: "Poppins";
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    line-height: 20px;
    color: #616773;
  `;
  const Labels = styled.label`
    font-family: "Poppins";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    color: #616773;
  `;

  const navigate = useNavigate();
  const [version, setVersion] = useState("");
  const [loading, setLoading] = useState(false);
  const [file, setFile] = useState({
    uploaded_file: "",
    preview_file: "",
  });
  const [file1, setFile1] = useState({
    uploaded_file1: "",
    preview_file1: "",
  });
  const [file2, setFile2] = useState({
    uploaded_file2: "",
    preview_file2: "",
  });

  const [imageSizeErr, setImageSizeErr] = useState("");
  const [imageSize, setImageSize] = useState("");
  const [imageSize2, setImageSize2] = useState("");

  const fileUpload1 = (selectedFile) => {
    if (selectedFile) {
      const data = selectedFile[0];
      if (!data.name.match(/\.(exe|zip|dmg|elf|AppImage|appimage)$/)) {
        setImageSize("File must be one of exe, zip, dmg, elf, AppImage");
        setFile1({
          uploaded_file1: "",
          preview_file1: "",
        });
        return false;
      } 
      // else if (300 * 1024 * 1024 < data.size) {
      //   setImageSize("File must be of size  300MB only ");
      //   setFile1({
      //     uploaded_file1: "",
      //     preview_file1: "",
      //   });
      //   return false;
      // }
      else {
        setImageSize("");
        const file = selectedFile[0];
        const reader = new FileReader();
        reader.onload = () => {
          if (reader.readyState === 2) {
            setFile1({
              uploaded_file1: selectedFile[0],
              preview_file1: reader.result,
            });
          }
        };
        reader.readAsDataURL(file);
        return true;
      }
    }
    return false;
  };

  const fileUpload2 = (selectedFile) => {
    if (selectedFile) {
      const data = selectedFile[0];
      if (!data.name.match(/\.(exe|zip|dmg|elf|AppImage|appimage)$/)) {
        setImageSize2("File must be one of exe, zip, dmg, elf, AppImage");
        setFile2({
          uploaded_file2: "",
          preview_file2: "",
        });
        return false;
      } 
      // else if (300 * 1024 * 1024 < data.size) {
      //   setImageSize2("File must be of size  300MB only ");
      //   setFile2({
      //     uploaded_file2: "",
      //     preview_file2: "",
      //   });
      //   return false;
      // } 
      else {
        setImageSize2("");
        const file = selectedFile[0];
        const reader = new FileReader();
        reader.onload = () => {
          if (reader.readyState === 2) {
            setFile2({
              uploaded_file2: selectedFile[0],
              preview_file2: reader.result,
            });
          }
        };
        reader.readAsDataURL(file);
        return true;
      }
    }
    return false;
  };

  const fileUpload = (selectedFile) => {
    if (selectedFile) {
      const data = selectedFile[0];
      if (!data.name.match(/\.(exe|zip|dmg|elf|AppImage|appimage)$/)) {
        setImageSizeErr("File must be one of exe, zip, dmg, elf, AppImage");
        setFile({
          uploaded_file: "",
          preview_file: "",
        });
        return false;
      } 
      // else if (300 * 1024 * 1024 < data.size) {
      //   setImageSizeErr("File must be of size  300MB only ");
      //   setFile({
      //     uploaded_file: "",
      //     preview_file: "",
      //   });
      //   return false;
      // }
       else {
        setImageSizeErr("");
        const file = selectedFile[0];
        const reader = new FileReader();
        reader.onload = () => {
          if (reader.readyState === 2) {
            setFile({
              uploaded_file: selectedFile[0],
              preview_file: reader.result,
            });
          }
        };
        reader.readAsDataURL(file);
        return true;
      }
    }
    return false;
  };

  const [errorName, setErrorName] = useState("");
  const [errorApp, setErrorApp] = useState("");

  const [name, setName] = useState("");
  const [error, setError] = useState("");

  function validateName(name) {
    const re = /^[0-9.]*$/; // regular expression to match letters and spaces
    return re.test(name);
  }




  function handleChange(event) {
    const value = event.target.value;
    if (validateName(value)) {
      setName(value);
      setErrorName("");
      setError("");
    } else {
      setErrorName("")
      setError("Please enter a valid Version (i.e '1.0.1')");
    }
  }

  // const validate = (values) => {
  //   if (!values.version_No) {
  //     setErrorName("Version number is required");
  //   }

  //   if (!values.WinOsFile || !values.MacOsFile || !values.LinuxOsFile) {
  //     return false;
  //   } else {
  //     setErrorName("");
  //     setErrorApp("");
  //     return true;
  //   }
  // };
  const postData = () => {
    if (name) {
      if (file.uploaded_file || file1.uploaded_file1 || file2.uploaded_file2) {
        
        var data = new FormData();
        data.append("version_No", name);
        data.append("WinOsFile", file.uploaded_file);
        data.append("MacOsFile", file1.uploaded_file1);
        data.append("LinuxOsFile", file2.uploaded_file2);

        setLoading(true);
        uploadDesktopApplication(data)
          .then((res) => {
            setLoading(false);
            navigate("/upload-version-list");
          })
          .catch((error) => {
            setLoading(false);
            console.log(error, "error");
          });
      } else {
        setImageSize2("Atleast one file is required");
      }
    } else {
      setError("")
      setErrorName("Version number is required");
    }
  };

  return (
    <React.Fragment>
      <section className="d-flex" style={{ padding: "10px 0" }}>
        <div className="container-fluid uploadAppHeader">
          <Header title="Upload Desktop App" />
          <div className="text-start mt-3 mb-3">
            <div
              className="d-flex align-items-center"
              role="button"
              onClick={() => navigate(-1)}
            >
              <i>
                <img src={backArrow} alt="back-Arrow" />
              </i>
              <span className="font-poppins-regular backBtn">
                &nbsp;&nbsp;&nbsp;Back
              </span>
            </div>
          </div>
          <FormGroup>
            <section className="addUsers" style={{ padding: "30px 50px" }}>
              <div className="container-fluid">
                <form>
                  {/* <div className="heading">Add New Desktop Application</div> */}
                  <div className="row">
                    <div className="col-lg-6 col-md-12 col-sm-12">
                      <div className="form-group mt-3">
                        <Label>
                          Version Number <span className="text-danger">*</span>
                        </Label>
                        <input
                          type="text"
                          name="Version_Number"
                          id="versionName"
                          value={name}
                          onChange={handleChange}
                          // onChange={(e) => {setVersion(e.target.value)
                          //   setFormErrors({...formerrors,version_No:""})
                          // }}
                          className="form-control bdr-radius team-select"
                          required
                        />
                      </div>
                      {error && <p style={{ color: "red" , fontSize: '13px'}}>{error}</p>}
                      <div className="text-alert font-poppins-medium">
                        <span role="alert" style={{ color: "red" , fontSize: '13px'}}>
                          {errorName}
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-lg-12 col-md-12 col-sm-12"></div>
                  </div>
                  <div className="row">
                    <div className="col-lg-12 col-md-12 col-sm-12">
                      <div className="form-group mt-2 uploadAppInputFile">
                        <Labels>
                          {" "}
                          Upload Windows Application Here
                          <span className="text-danger">*</span> :{" "}
                        </Labels>
                        <input
                          type="file"
                          name="Upload_file"
                          id="winFileUpload"
                          className="bdr-radius"
                          onChange={(e) => {
                            fileUpload(e.target.files);
                          }}
                        />
                      </div>
                      {imageSizeErr ? (
                          <>
                            <div>
                              <p
                                style={{ color: "red" }}
                                className="text-danger"
                              >
                                {imageSizeErr}
                              </p>
                            </div>
                          </>
                        ) : (
                          ""
                        )}
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-lg-12 col-md-12 col-sm-12">
                      <div className="form-group mt-2 uploadAppInputFile">
                        <Labels>
                          {" "}
                          Upload MacOS Application Here
                          <span className="text-danger">*</span> :{" "}
                        </Labels>
                        <input
                          type="file"
                          name="Upload_file"
                          className="bdr-radius"
                          onChange={(e) => {
                            fileUpload1(e.target.files);
                            // setFormErrors({...formerrors,desktop_Application:""})
                          }}
                        />
                      </div>
                      {imageSize ? (
                          <>
                            <div>
                              <p
                                style={{ color: "red" , fontSize: '13px'}}
                                className="text-danger"
                              >
                                {imageSize}
                              </p>
                            </div>
                          </>
                        ) : (
                          ""
                        )}
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-lg-12 col-md-12 col-sm-12">
                      <div className="form-group mt-2 uploadAppInputFile">
                        <Labels>
                          {" "}
                          Upload Linux Application Here
                          <span className="text-danger">*</span> :{" "}
                        </Labels>
                        <input
                          type="file"
                          name="Upload_file"
                          className="bdr-radius"
                          onChange={(e) => {
                            fileUpload2(e.target.files);
                            // setFormErrors({
                            //   ...formerrors,
                            //   desktop_Application: "",
                            // });
                          }}
                        />
                      </div>
                      {imageSize2 ? (
                          <>
                            <div>
                              <p
                                style={{ color: "red" }}
                                className="text-danger"
                              >
                                {imageSize2}
                              </p>
                            </div>
                          </>
                        ) : (
                          ""
                        )}
                    </div>
                  </div>
                  <div className="text-alert font-poppins-medium">
                    <span role="alert" className="formErr">
                      {errorApp}
                    </span>
                  </div>
                  <div className="mt-5 d-flex justify-content-center align-items-center">
                    <div className="row">
                      <div className="col mt-1">
                        <button
                          type="button"
                          onClick={postData}
                          className="btn buttonDefault me-2"
                          disabled={loading}
                        >
                          {!loading && <span>Save</span>}
                          {loading && (
                            <span
                              className="indicator-progress"
                              style={{ display: "flex" }}
                            >
                              Saving
                              <span className="spinner-border spinner-border-sm mt-1 ml-3"></span>
                            </span>
                          )}
                        </button>
                      </div>
                      <div className="col mt-1">
                        <button
                          type="button"
                          to="/upload-version-list"
                          className="btn buttonDefault me-2"
                          style={{
                  background: "#8A899C",
                }}
                        >
                          <Link
                          className="hoverWhite"
                            to="/upload-version-list"
                            style={{ textDecoration: "none;", color: "white" }}
                          >
                            {" "}
                            Cancel
                          </Link>
                        </button>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </section>
          </FormGroup>
        </div>
      </section>
    </React.Fragment>
  );
}
