import React, { useState, useEffect } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useDispatch, shallowEqual, useSelector } from "react-redux";
import { authUserLogout } from "../reduxAuthentication/actions";
import { checkJWTToken } from "../authApi/AuthCRUD";

import styled from "styled-components";
import * as FaIcons from "react-icons/fa";
import * as AiIcons from "react-icons/ai";
import * as IoIcons from "react-icons/io";
import * as RiIcons from "react-icons/ri";
import * as IconName from "react-icons/bs";
import "../css/style.css";
import Logo from "../Images/odysseylogo.png";

const SidebarSpace = styled.div`
  background: #fff;
  padding-bottom: 10px;
`;
const ProfileContent = styled.div`
  text-align: center;
  margin: 20px 5px;
`;
const LogoContent = styled.div`
  padding: 20px 0;
  display: flex;
  justify-content: center;
  align-items: center;
`;
const LogoName = styled.div`
  font-weight: 600;
  font-size: 25px;
  margin-left: 5px;
`;
const ProfileName = styled.div`
  font-weight: 600;
  font-size: 20px;
`;
const ProfileEmail = styled.div`
  font-size: 13px;
  color: gray;
`;

const Sidebar = (props) => {
  let Detail = useSelector((store) => store.auth.data, shallowEqual);
  const UserToken = Detail.Token;

  let navigate = useNavigate();
  let dispatch = useDispatch();
  const storedImg = useSelector(
    (store) => store.auth.userProfile,
    shallowEqual
  );

  let fullName = [];
  let initials = "";
  fullName = Detail.FullName.split(" ");

  if (fullName.length === 2) {
    initials = fullName.shift().charAt(0) + fullName.pop().charAt(0);
  } else {
    initials = fullName.shift().charAt(0);
  }

  var UserName = localStorage.getItem("FullName");
  var UserEmail = localStorage.getItem("Email");

  const location = useLocation();
  let getlocation = location.pathname;

  const [open, setOpen] = React.useState(false);
  const [adminSelectOpen, setAdminSelectOpen] = React.useState(false);
  const [storedProfileImage, setStoredProfileImage] = useState("");

  useEffect(() => {
    // Auto logout user when token is expire
    if (!checkJWTToken(UserToken)) {
      localStorage.clear();
      dispatch(authUserLogout());
      navigate("/");
    } else {
      setStoredProfileImage(storedImg);
    }
  }, [storedImg]);

  const handleClick = () => {
    setOpen(!open);
  };

  const handleAdminSelectClick = () => {
    setAdminSelectOpen(!adminSelectOpen);
  };

  return (
    <React.Fragment>
      <SidebarSpace
        className="h-100 overflow-auto bg-light flex-column sticky-top pl-0"
        id="custom-scroll"
      >
        <LogoContent>
          <div className="logoImage">
            <img data-cke-saved-src={Logo} src={Logo} alt="Logo" width={60} />
          </div>
          <LogoName>Odyssey</LogoName>
        </LogoContent>
        <hr style={{ margin: "0", height: "3px", color: "#CCCCCC" }} />
        <ProfileContent>
          <div className="ProfileContent">
            {storedProfileImage && storedProfileImage != "null" ? (
              <>
                <img
                  src={storedProfileImage}
                  alt="ProfileIamge"
                  className="ProfileIamge"
                />
              </>
            ) : (
              <>
                <div
                  className="ProfileIamge rounded-circle mb-3 d-flex justify-content-center"
                  style={{
                    width: " 70px",
                    height: "70px",
                    background:
                      "linear-gradient(264.71deg, #8F009B 18.58%, #420085 128.77%)",
                  }}
                >
                  <div className="d-flex align-items-center text-white fs-3 text-uppercase">
                    {initials}
                  </div>
                </div>
              </>
            )}

            <div className="sidebar-user-box mt-2">
              <h5 className="font-poppins-medium">{Detail.FullName}</h5>
              <span className="font-poppins-regular">{Detail.Email}</span>
            </div>
          </div>
          <ProfileName>{UserName}</ProfileName>
          <ProfileEmail>{UserEmail}</ProfileEmail>
        </ProfileContent>
        <hr style={{ margin: "0", height: "3px", color: "#CCCCCC" }} />
        <div className="sideNav ">
          <div className="menuContent mt-3">
            <ul>
              <li
                className={`${
                  getlocation === "/superadmindashboard"
                    ? "active"
                    : getlocation === "/"
                    ? "active"
                    : ""
                }`}
              >
                <Link to="/superadmindashboard" className="nav-items-link">
                  <div>
                    <AiIcons.AiFillHome className="icon" />
                  </div>
                  <div className="item">Dashboard</div>
                </Link>
              </li>
              <li
                className={`${
                  getlocation === "/superadminsubscription" ? "active" : ""
                }`}
              >
                <Link to="/superadminsubscription" className="nav-items-link">
                  <div>
                    <IoIcons.IoIosAddCircle className="icon" />
                  </div>
                  <div className="item">Subscription</div>
                </Link>
              </li>

              <li
                className={`${
                  getlocation === "/upload-version-list"
                    ? "active"
                    : getlocation === "/upload-app"
                    ? "activeDropClass"
                    : getlocation === "/update-app"
                    ? "activeDropClass"
                    : getlocation === "/upload-app-details"
                    ? "activeDropClass"
                    : ""
                }`}
              >
                <Link to="/upload-version-list" className="nav-items-link">
                  <div>
                    <AiIcons.AiOutlineUpload className="icon" />
                  </div>
                  <div className="item">Upload</div>
                </Link>
              </li>

              <li
                className={`${
                  getlocation === "/email-template"
                    ? "active"
                    : getlocation === "/edit-email-template"
                    ? "activeDropClass"
                    : ""
                }`}
              >
                <Link to="/email-template" className="nav-items-link">
                  <div>
                    <FaIcons.FaEnvelope className="icon" />
                  </div>
                  <div className="item">Email Templates</div>
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </SidebarSpace>
    </React.Fragment>
  );
};
export default Sidebar;
