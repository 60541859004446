import React, { useState } from "react";
import "./SignUpNew.css";
import odyseyyLogo from "../Images/formImages/odyssey-logo.svg";
import odyseyySmLogo from "../Images/formImages/odyssey-sm-logo.svg";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import * as Yup from "yup";
import clsx from "clsx";
import { register } from "../authApi/AuthCRUD";
import { useDispatch } from "react-redux";
import { authUserRegister } from "../reduxAuthentication/actions";
import eyeSlashIcon from "../Images/formImages/eye-slash-icon.svg";
import eyeIcon from "../Images/formImages/eye-icon.svg";

// const EmailDomain = [".com", ".in", ".co", ".edu" ,".org", ".net", ".mil", ".gov"]
const registerPasswordSchema = Yup.object().shape({
    compName: Yup.string()
        .required("Company name is required*")
        .trim(),
    compUsername: Yup.string()
        .required("Username is required*"),
    compemail: Yup.string()
        .email("Enter valid email address*")
        .max(50, "Maximum 50 symbols")
        .required("Email is required*"),
    comppassword: Yup.string()
        .required("Password is required*")
        .min(6, "Minimum 6 characters is required")
        .max(20, "Maximum 20 characters is required*")
        .trim()
        .matches(/[0-9]/, 'Password requires a number')
        .matches(/[a-z]/, 'Password requires a lowercase letter')
        .matches(/[A-Z]/, 'Password requires an uppercase letter')
        .matches(/[~`!.#%&*_+@?^${}()|[\]\\]/, 'Password requires an one special case character'),
});

export default function SignUpNew() {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(false);
    const [passwordShown, setPasswordShown] = useState(false);
    const [responseStatus, setResponseStatus] = useState('');

    const togglePasswordVisiblity = () => {
        setPasswordShown(passwordShown ? false : true);
    };

    const formik = useFormik({
        initialValues: {
            compName: "",
            compemail: "",
            comppassword: "",
            compUsername: "",
        },
        validationSchema: registerPasswordSchema,
        onSubmit: (values, { setStatus, setSubmitting }) => {
            let data = {
                Company_Name: values.compName.replaceAll(' ',''),
                Comp_User_Name: values.compUsername,
                Company_Email: values.compemail,
                Company_password: values.comppassword.replaceAll(' ','')
            }
            setLoading(true);
            register(data)
                .then((res) => {
                    setLoading(false);
                    dispatch(authUserRegister(res.data.Data));
                    navigate("/verify/your/email");
                    setResponseStatus('');
                })
                .catch((error) => {
                    // console.log(error.res.data)
                    setLoading(false);
                    setResponseStatus(error.response.data.Message);
                    // console.log(error);
                });
        },
    });
    return (
        <React.Fragment>
            <div className="sign-up-wrapper d-none d-md-block d-lg-block d-xl-block">
                <div className="content">
                    <div className="container">
                        <div className="mb-7 d-flex justify-content-end text-white">
                            <div className="d-flex align-items-center gap-22">
                                <span className="font-poppins-regular account-head">Already have an account?</span>
                                <Link to="/" className="text-decoration-none">
                                    <span className="text-white account-btn" role="button">
                                        <span className="font-poppins-medium">Sign In</span>
                                    </span>
                                </Link>
                            </div>
                        </div>
                        <div className="row no-gutters">
                            <div className="col-md-5 bg-left-section position-relative">
                                <div className="position-absolute logo-center">
                                    <img src={odyseyyLogo} alt="logo" />
                                </div>
                            </div>
                            <div className="col-md-7 bg-right-form">
                                <div className="row">
                                    <div className=""  style={{padding: '2.5rem 5rem'}}>
                                        <div className="text-center">
                                            <h1 className="main-heading">Get started with Odyssey</h1>
                                            <span className="sub-heading font-poppins-regular">Start your 30-day free trial.</span>
                                        </div>

                                        <div>
                                            <form className="mt-5" onSubmit={formik.handleSubmit}>
                                                <div>
                                                    {responseStatus !== '' ?
                                                        (<><span className="mt-2 mb-3 d-flex justify-content-center text-danger font-poppins-medium">{responseStatus}</span></>)
                                                        : null
                                                    }
                                                </div>
                                                <div className="row">
                                                    <div className="form-group col-sm-12 col-md-12 col-lg-6 col-xl-6 mb-3">
                                                        <label className="w-100 label-field font-poppins-medium">
                                                            Your Name*
                                                            <input
                                                                id="input-name-control"
                                                                type="text"
                                                                className={clsx(
                                                                    "mt-1 form-control input-field font-poppins-regular text-capitalize",
                                                                    {
                                                                        "is-invalid": formik.touched.compUsername && formik.errors.compUsername,
                                                                    },
                                                                    {
                                                                        "is-valid": formik.touched.compUsername && !formik.errors.compUsername,
                                                                    }
                                                                )}
                                                                autoComplete="off"
                                                                placeholder="Your Name"
                                                                {...formik.getFieldProps("compUsername")}
                                                                onChange={formik.handleChange}
                                                            />
                                                        </label>
                                                        {formik.touched.compUsername && formik.errors.compUsername && (
                                                            <div>
                                                                <div className='text-alert font-poppins-medium'>
                                                                    <span role='alert' className="formErr">{formik.errors.compUsername}</span>
                                                                </div>
                                                            </div>
                                                        )}
                                                    </div>
                                                    <div className="form-group col-sm-12 col-md-12 col-lg-6 col-xl-6 mb-3">
                                                        <label className="w-100 label-field font-poppins-medium">
                                                            Company Name*
                                                            <input
                                                                id="input-compname-control"
                                                                type="text"
                                                                className={clsx(
                                                                    "mt-1 form-control input-field font-poppins-regular text-capitalize",
                                                                    {
                                                                        "is-invalid": formik.touched.compName && formik.errors.compName,
                                                                    },
                                                                    {
                                                                        "is-valid": formik.touched.compName && !formik.errors.compName,
                                                                    }
                                                                )}
                                                                autoComplete="off"
                                                                placeholder="Company Name"
                                                                {...formik.getFieldProps("compName")}
                                                                onChange={formik.handleChange}
                                                            />
                                                        </label>
                                                        {formik.touched.compName && formik.errors.compName && (
                                                            <div>
                                                                <div className='text-alert font-poppins-medium'>
                                                                    <span role='alert' className="formErr">{formik.errors.compName}</span>
                                                                </div>
                                                            </div>
                                                        )}
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="form-group col-sm-12 col-md-12 col-lg-12 col-xl-12 mb-3">
                                                        <label className="w-100 label-field font-poppins-medium">
                                                            Email*
                                                            <input
                                                                id="input-email-control"
                                                                type="email"
                                                                className={clsx(
                                                                    "mt-1 form-control input-field font-poppins-regular",
                                                                    {
                                                                        "is-invalid": formik.touched.compemail && formik.errors.compemail,
                                                                    },
                                                                    {
                                                                        "is-valid": formik.touched.compemail && !formik.errors.compemail,
                                                                    }
                                                                )}
                                                                autoComplete="off"
                                                                placeholder="Enter Email"
                                                                {...formik.getFieldProps("compemail")}
                                                                onChange={formik.handleChange}
                                                            />
                                                        </label>
                                                        {formik.touched.compemail && formik.errors.compemail && (
                                                            <div>
                                                                <div className='text-alert font-poppins-medium'>
                                                                    <span role='alert' className="formErr">{formik.errors.compemail}</span>
                                                                </div>
                                                            </div>
                                                        )}
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="form-group col-sm-12 col-md-12 col-lg-12 col-xl-12 mb-2">
                                                        <label className="w-100 label-field font-poppins-medium">
                                                            Password*
                                                            <input
                                                                id="input-password-control"
                                                                type={passwordShown ? "text" : "password"}
                                                                className="mt-1 form-control input-field-psd  font-poppins-regular"
                                                                autoComplete="off"
                                                                placeholder="Password"
                                                                {...formik.getFieldProps("comppassword")}
                                                                onChange={formik.handleChange}
                                                            />
                                                        </label>
                                                        {passwordShown ?
                                                            <i onClick={togglePasswordVisiblity} role="button">
                                                                <img className="eye-icon" src={eyeIcon} alt="eyeicon" />
                                                            </i>
                                                            :
                                                            <i onClick={togglePasswordVisiblity} role="button">
                                                                <img className="eye-icon" src={eyeSlashIcon} alt="eyeicon" />
                                                            </i>
                                                        }
                                                        {formik.touched.comppassword && formik.errors.comppassword && (
                                                            <div>
                                                                <div className='text-alert font-poppins-medium'>
                                                                    <span role='alert' className="formErr">{formik.errors.comppassword}</span>
                                                                </div>
                                                            </div>
                                                        )}
                                                    </div>
                                                </div>

                                                <div id="myModal" className="modal fade" role="dialog">
                                                    <div className="modal-dialog">

                                                        <div className="modal-content">
                                                            <div className="modal-header">
                                                                {/* <button type="button" className="close" data-bs-dismiss="modal">&times;</button> */}
                                                                <h4 className="modal-title">Terms & Conditions</h4>
                                                            </div>
                                                            <div className="modal-body">
                                                                <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry.
                                                                    Lorem Ipsum has been the industry's standard dummy text ever since the 1500s,
                                                                    when an unknown printer took a galley of type and scrambled it to make a type specimen book.
                                                                    It has survived not only five centuries, but also the leap into electronic typesetting,
                                                                    remaining essentially unchanged. It was popularised in the 1960s with the release of
                                                                    Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing
                                                                    software like Aldus PageMaker including versions of Lorem Ipsum.</p>
                                                            </div>
                                                            <div className="modal-footer">
                                                                <button type="button" className="btn btn-secondary shadow-none" data-bs-dismiss="modal">Close</button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div>
                                                    <span className="account-endline font-poppins-regular">By creating an account, you are agree to the <span className="terms-c"><Link data-bs-toggle="modal"
                                                        data-bs-target="#myModal" > Terms & Conditions. </Link></span></span>
                                                </div>



                                                <div className="row">
                                                    <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12 mt-2 d-flex justify-content-center">
                                                        <button
                                                            type="submit"
                                                            className="btn mt-5 submit-btn font-poppins-regular"
                                                        >
                                                            {!loading && <span>Sign Up</span>}
                                                            {loading && (
                                                                <span
                                                                    className="indicator-progress"
                                                                    style={{ display: "block" }}
                                                                >
                                                                    Sign Up
                                                                    <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                                                                </span>
                                                            )}
                                                        </button>
                                                    </div>
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="d-block d-md-none d-lg-none d-xl-done">
                <div className="row m-0 p-0">
                    <div className="col-12 sm-screen-bg">
                        <div className="m-4 mt-5 d-flex justify-content-center align-items-center">
                            <span className="font-mont-regular account-sm-head">Already have an account?</span>&nbsp;&nbsp;
                            <Link to="/" className="text-decoration-none">
                                <span className="text-white account-sm-btn">
                                    <span>Sign In</span>
                                </span>
                            </Link>
                        </div>

                        <div className="m-5 d-flex justify-content-center">
                            <img src={odyseyySmLogo} alt="logo" />
                        </div>
                    </div>

                    <div className="row p-0 m-0">
                        <div className="col-12 bg-white">
                            <div className="mt-5 mb-5 mx-1">
                                <div className="text-center">
                                    <h1 className="main-sm-heading">Get started with Odyssey</h1>
                                    <span className="sub-heading">Start your 30-day free trial.</span>
                                </div>

                                <div>
                                    <form className="mt-5" onSubmit={formik.handleSubmit}>
                                        <div className="row m-0">
                                            <div className="form-group col-6">
                                                <label className="w-100 mb-3 label-field font-poppins-medium">
                                                    Your Name*
                                                    <input
                                                        id="input-name-control"
                                                        type="text"
                                                        className="mt-1 form-control input-sm-field font-poppins-regular text-capitalize"
                                                        autoComplete="off"
                                                        placeholder="Your Name"
                                                        {...formik.getFieldProps("compUsername")}
                                                        onChange={formik.handleChange}
                                                    />
                                                    {formik.touched.compUsername && formik.errors.compUsername && (
                                                        <div>
                                                            <div className='text-alert font-poppins-medium'>
                                                                <span role='alert' className="formErr">{formik.errors.compUsername}</span>
                                                            </div>
                                                        </div>
                                                    )}
                                                </label>
                                            </div>
                                            <div className="form-group col-6">
                                                <label className="w-100 mb-3 label-field font-poppins-medium">
                                                    Comapny Name*
                                                    <input
                                                        id="input-compname-control"
                                                        type="text"
                                                        className="mt-1 form-control input-sm-field font-poppins-regular text-capitalize"
                                                        autoComplete="off"
                                                        placeholder="Company Name"
                                                        {...formik.getFieldProps("compName")}
                                                        onChange={formik.handleChange}
                                                    />
                                                    {formik.touched.compName && formik.errors.compName && (
                                                        <div>
                                                            <div className='text-alert font-poppins-medium'>
                                                                <span role='alert' className="formErr">{formik.errors.compName}</span>
                                                            </div>
                                                        </div>
                                                    )}
                                                </label>
                                            </div>
                                        </div>
                                        <div className="row m-0">
                                            <div className="form-group col-sm-12">
                                                <label className="w-100 mb-3 label-field font-poppins-medium">
                                                    Email*
                                                    <input
                                                        id="input-email-control"
                                                        type="text"
                                                        className="mt-1 form-control input-sm-field font-poppins-regular"
                                                        autoComplete="off"
                                                        placeholder="Enter Email"
                                                        {...formik.getFieldProps("compemail")}
                                                        onChange={formik.handleChange}
                                                    />
                                                    {formik.touched.compemail && formik.errors.compemail && (
                                                        <div>
                                                            <div className='text-alert font-poppins-medium'>
                                                                <span role='alert' className="formErr">{formik.errors.compemail}</span>
                                                            </div>
                                                        </div>
                                                    )}
                                                </label>
                                            </div>
                                        </div>
                                        <div className="row m-0">
                                            <div className="form-group col-sm-12">
                                                <label className="w-100 mb-3 label-field font-poppins-medium">
                                                    Password*
                                                    <input
                                                        id="input-password-control"
                                                        type={passwordShown ? "text" : "password"}
                                                        className="mt-1 form-control input-sm-field font-poppins-regular"
                                                        autoComplete="off"
                                                        placeholder="Password"
                                                        {...formik.getFieldProps("comppassword")}
                                                        onChange={formik.handleChange}
                                                    />
                                                    {passwordShown ?
                                                        <i onClick={togglePasswordVisiblity} role="button">
                                                            <img className="eye-sm-icon" src={eyeIcon} alt="eyeicon" />
                                                        </i>
                                                        :
                                                        <i onClick={togglePasswordVisiblity} role="button">
                                                            <img className="eye-sm-icon" src={eyeSlashIcon} alt="eyeicon" />
                                                        </i>
                                                    }
                                                    {formik.touched.comppassword && formik.errors.comppassword && (
                                                        <div>
                                                            <div className='text-alert font-poppins-medium'>
                                                                <span role='alert' className="formErr">{formik.errors.comppassword}</span>
                                                            </div>
                                                        </div>
                                                    )}
                                                </label>
                                            </div>
                                        </div>
                                        <div className="row m-0">
                                            <span className="account-sm-endline font-poppins-regular">By creating an account, you agree to the <span className="terms-c">Terms & Conditions.</span></span>
                                        </div>
                                        <div className="row">
                                            <div className="col-sm-12 mt-2 d-flex justify-content-center">
                                                <button
                                                    type="submit"
                                                    className="btn mt-5 submit-sm-btn"
                                                    disabled={loading}
                                                >
                                                    {!loading && <span>Sign Up</span>}
                                                    {loading && (
                                                        <span
                                                            className="indicator-progress font-poppins-regular"
                                                            style={{ display: "block" }}
                                                        >
                                                            Sign Up
                                                            <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                                                        </span>
                                                    )}
                                                </button>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    )
}