import React, { useEffect } from "react";
import { Routes, Route, useNavigate } from "react-router-dom";
import { useDispatch, shallowEqual, useSelector } from "react-redux";
import { authUserLogout } from "../reduxAuthentication/actions";
import { checkJWTToken } from "../authApi/AuthCRUD";

import PageNotFound from "../errorPage/PageNotFound";
import PageUnAuthorize from "../errorPage/PageUnAuthorize";
import { SuperAdminDashboard } from "../SuperAdminView/SuperAdminDashboard";
import { SuperAdminSupscription } from "../SuperAdminView/SuperAdminSupscription";
import SuperAdminEditSupscription from "../SuperAdminView/SuperAdminEditSupscription";
import ProfilePage from "../profileComponent/ProfilePage";
import SuperAdminCreateSupscription from "../SuperAdminView/SuperAdminCreateSupscription";
import Upload from "../UploadApplication/Upload";
import UploadApp from "../UploadApplication/UploadApp";
import ViewUpload from "../UploadApplication/ViewUpload";
import EditEmailTemplate from "../EmailTempletes/EditEmailTemplate";
import EmailTemplateList from "../EmailTempletes/Email-template";
import CreateEmailTemplete from "../EmailTempletes/CreateEmailTemplete";

const NavPage = (props) => {
  let navigate = useNavigate();
  let dispatch = useDispatch();

  let Authentication = useSelector((store) => store.auth.data, shallowEqual);
  const Tenant = Authentication.Tenant;
  const UserToken = Authentication.Token;

  useEffect(() => {
    // Auto logout user when token is expire
    if (!checkJWTToken(UserToken)) {
      localStorage.clear();
      dispatch(authUserLogout());
      navigate("/");
    }
  }, []);

  return (
    <>
      <div>
        <section>
          <Routes>
            <Route path="/" element={<SuperAdminDashboard />} />
            <Route path="*" element={<PageNotFound />} />

            <Route path="/pagenotfound" element={<PageNotFound />} />
            <Route path="/unauthorize" element={<PageUnAuthorize />} />
            <Route
              path="/superadmindashboard"
              element={<SuperAdminDashboard />}
            />
            <Route
              path="/superadminsubscription"
              element={<SuperAdminSupscription />}
            />
            <Route
              path="/superadminsubscription/editsubscription"
              element={<SuperAdminEditSupscription />}
            />

            <Route
              path="/superadminsubscription/createsubscription"
              element={<SuperAdminCreateSupscription />}
            />

            <Route path="/profile-page" element={<ProfilePage />} />

            {/* -------------- */}

            <Route path="/upload-version-list" element={<Upload />} />
            <Route path="/upload-app" element={<UploadApp />} />
            <Route path="/upload-app-details" element={<ViewUpload />} />
            {/* <Route path="/update-app" element={<UpdateUpload />} /> */}

            <Route path="/email-template" element={<EmailTemplateList />} />
            {/* <Route
              path="/email-template/Create-email-template"
              element={<CreateEmailTemplete />}
            /> */}
            <Route
              path="/email-template/edit-email-template"
              element={<EditEmailTemplate />}
            />
          </Routes>
        </section>
      </div>
    </>
  );
};

export default NavPage;
