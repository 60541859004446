import React, { useEffect, useState } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
import CheckIamge from "../Images/checkImage.png";
import ReactPaginate from "react-paginate";
import sorticon from "../Images/sorticon.svg";
import ModalImage from "react-modal-image";
import {
  subscriptonPlans,
  subscriptonEnable,
  subscriptonDisable,
} from "../authApi/AuthCRUD";
import { shallowEqual, useSelector } from "react-redux";
import { Header } from "../layoutComponent/Header";
import ClockLoader from "react-spinners/ClockLoader";
import lastWorkedOn from "../Images/livedashboard/last-worked-ss.svg";
import "./super.css";
import WhiteEditBtn from "../Images/WhiteEditBtn.png";
import edit from "../Images/edit.svg";
import enableIcon from "../Images/livedashboard/enable-icon.svg";
import disableIcon from "../Images/livedashboard/disable-icon.svg";
import Dropdown from "react-bootstrap/Dropdown";

const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
  <a
    className="dropLink"
    href="/"
    ref={ref}
    onClick={(e) => {
      e.preventDefault();
      onClick(e);
    }}
  >
    {children}
    <span className="threedots" />
  </a>
));

export const SuperAdminSupscription = () => {
  let Authentication = useSelector((store) => store.auth.data, shallowEqual);
  const Tenant = Authentication.Tenant;
  const UserRole = Authentication.User_Role;
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  let [subscriptionData, setSubscriptionData] = useState([]);
  const { search } = useLocation();
  const projectId = new URLSearchParams(search).get("id");

  useEffect(() => {
    getData();
  }, []);

  const getData = () => {
    setLoading(true);
    subscriptonPlans()
      .then((res) => {
        setLoading(false);
        setSubscriptionData(res.data.Data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  //DisablePlan
  const disablePlan = (id) => {
    subscriptonDisable(id)
      .then((res) => {
        getData();
      })
      .catch((err) => {
        console.log(err, "error");
      });
  };
  //EnableUPlan
  const enableUPlan = (id) => {
    subscriptonEnable(id)
      .then((res) => {
        getData();
      })
      .catch((err) => {
        console.log(err, "error");
      });
  };

  return (
    <>
      <section className="d-flex" style={{ padding: "10px 0" }}>
        <div className="container-fluid">
          <Header title="Subscription Plan" />

          <div className="subscriptionHeader">
            <div className="d-flex justify-content-end align-items-center">
              <div className="btn-div-width">
                <Link
                  type="button"
                  className="btn btn-lg w-100 font-poppins-medium align-center addSubscriptionbtn"
                  style={{
                    borderRadius: "5px",
                    borderColor: "#6300B0",
                    background: "#6300B0",
                    fontFamily: "Poppins",
                    fontStyle: "normal",
                    color: "#FFF",
                    fontWeight: "500",
                    fontSize: "12px",
                    padding: "10px 20px",
                  }}
                  to={{
                    pathname: "/superadminsubscription/createsubscription",
                  }}
                >
                  <span>+ New Plan</span>
                </Link>
              </div>
            </div>
          </div>

          {loading ? (
            <>
              <div className="d-flex justify-content-center text-center loaderDiv">
                <ClockLoader color="#6400B0" speedMultiplier={3} />
                <span className="loading">Loading....</span>
              </div>
            </>
          ) : (
            <>
              {!subscriptionData || subscriptionData === null ? (
                <>
                  <h5 className="text-secondary d-flex justify-content-center mt-5 font-poppins-medium">
                    No result available
                  </h5>
                </>
              ) : (
                <>
                  <section className="pricing">
                    <div className="row">
                      {subscriptionData.map((data, index) => {
                        return (
                          <React.Fragment key={index}>
                            <div className="col-sm-12 col-md-4 col-lg-4 col-xl-4 mt-2">
                              {data.IsDeleted ? (
                                <>
                                  <div
                                    className="disPriceCard"
                                    title="Currently, the plan is disabled, and you cannot edit it until you enable it first"
                                  >
                                    <div className="dispriceHeader p-0">
                                      <div className="d-flex justify-content-end align-items-center">
                                        <Dropdown
                                          className="OptionChange"
                                          style={{
                                            background: "#6414b5",
                                            color: "#fff",
                                          }}
                                        >
                                          <Dropdown.Toggle as={CustomToggle} />
                                          <Dropdown.Menu size="sm" title="">
                                            {/* <Dropdown.Item
                                              class="drpdwnitem"
                                              style={{
                                                color: "#616773",
                                                opacity: "0.9",
                                                fontSize: "12px",
                                                fontWeight: "400",
                                                fontFamily: "Poppins",
                                                fontStyle: "normal",
                                              }}
                                            >
                                              <div className="d-flex test ">
                                                <img src={edit} alt="icon" />
                                                &nbsp;
                                                <Link
                                                  to={`/superadminsubscription/editsubscription?planid=${data.Id}`}
                                                >
                                                  Edit
                                                </Link>{" "}
                                              </div>
                                            </Dropdown.Item> */}
                                            <Dropdown.Item className="drpdwnitem">
                                              <div
                                                className="d-flex"
                                                id="1"
                                                style={{ color: "blue" }}
                                                onClick={() =>
                                                  enableUPlan(data.Id)
                                                }
                                              >
                                                <img
                                                  src={enableIcon}
                                                  alt="icon"
                                                />
                                                <div> &nbsp; Enable</div>
                                              </div>
                                            </Dropdown.Item>
                                          </Dropdown.Menu>
                                        </Dropdown>
                                      </div>
                                      <div
                                        className="priceHeaderInner"
                                        style={{
                                          padding: "0px 20px 20px 20px",
                                        }}
                                      >
                                        <div className="dispriceHead">
                                          {data.Plan_Name}
                                        </div>
                                        <div className="priceAmount">
                                          <span className="disamount">
                                            ${data.Price}{" "}
                                          </span>
                                          <span className="amountLabel">
                                            for {data.Users} Users
                                          </span>
                                        </div>
                                      </div>
                                    </div>

                                    <div
                                      className="priceDesc"
                                      id="custom-scroll"
                                    >
                                      {data.PlanDetails.map((list, i) => {
                                        return (
                                          <React.Fragment key={i}>
                                            <div>
                                              <div className="priceDescList  d-flex align-items-center">
                                                <span>
                                                  <img
                                                    src={CheckIamge}
                                                    alt="checkImage"
                                                    width={10}
                                                    height={10}
                                                  />
                                                </span>
                                                <span className="listItems">
                                                  {list}
                                                </span>
                                              </div>
                                            </div>
                                          </React.Fragment>
                                        );
                                      })}
                                    </div>
                                  </div>
                                </>
                              ) : (
                                <>
                                  <div className="priceCard">
                                    <div className="priceHeader p-0">
                                      <div className="d-flex justify-content-end align-items-center">
                                        <Dropdown className="OptionChange"
                                        style={{
                                            background: "#6414b5",
                                            color: "#fff",
                                          }}
                                          >
                                          <Dropdown.Toggle as={CustomToggle} />
                                          <Dropdown.Menu size="sm" title="">
                                            <Dropdown.Item
                                              class="drpdwnitem"
                                              style={{
                                                color: "#ffffff",
                                                opacity: "0.9",
                                                fontSize: "12px",
                                                fontWeight: "400",
                                                fontFamily: "Poppins",
                                                fontStyle: "normal",
                                              }}
                                            >
                                              <div className="d-flex ">
                                                <img src={edit} alt="icon" />
                                                &nbsp;
                                                <Link
                                                  to={`/superadminsubscription/editsubscription?planid=${data.Id}`}
                                                >
                                                  Edit
                                                </Link>{" "}
                                              </div>
                                            </Dropdown.Item>
                                            <Dropdown.Item className="drpdwnitem">
                                              <div
                                                className="d-flex"
                                                style={{ color: "red" }}
                                                id="2"
                                                onClick={() =>
                                                  disablePlan(data.Id)
                                                }
                                              >
                                                <img
                                                  src={disableIcon}
                                                  alt="icon"
                                                />
                                                <div> &nbsp; Disable</div>
                                              </div>
                                            </Dropdown.Item>
                                          </Dropdown.Menu>
                                        </Dropdown>
                                      </div>
                                      <div
                                        className="priceHeaderInner"
                                        style={{
                                          padding: "0px 20px 20px 20px",
                                        }}
                                      >
                                        <div className="priceHead">
                                          {data.Plan_Name}
                                        </div>
                                        <div className="priceAmount">
                                          <span className="amount">
                                            ${data.Price}{" "}
                                          </span>
                                          <span className="amountLabel">
                                            {" "}
                                            for {data.Users} Users
                                          </span>
                                        </div>
                                      </div>
                                    </div>

                                    <div
                                      className="priceDesc"
                                      id="custom-scroll"
                                    >
                                      {data.PlanDetails.map((list, i) => {
                                        return (
                                          <React.Fragment key={i}>
                                            <div>
                                              <div className="priceDescList  d-flex align-items-center">
                                                <span>
                                                  <img
                                                    src={CheckIamge}
                                                    alt="checkImage"
                                                    width={10}
                                                    height={10}
                                                  />
                                                </span>
                                                <span className="listItems">
                                                  {list}
                                                </span>
                                              </div>
                                            </div>
                                          </React.Fragment>
                                        );
                                      })}
                                    </div>
                                  </div>
                                </>
                              )}
                            </div>
                          </React.Fragment>
                        );
                      })}
                    </div>
                  </section>
                </>
              )}
            </>
          )}
        </div>
      </section>
    </>
  );
};
