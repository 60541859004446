import React, { useRef, useState, useEffect } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import "./Email-template.css";
import EditIcon from "../Images/edit.svg";
import delet from "../Images/delet.svg";
import Swal from "sweetalert2";

import { Header } from "../layoutComponent/Header";
import backArrow from "../Images/livedashboard/back-arrow.svg";
import { deleteEmailTemplate, getEmailTemplateList } from "../authApi/AuthCRUD";
import ClockLoader from "react-spinners/ClockLoader";

export default function EmailTemplateList() {
  const navigate = useNavigate();
  let [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  useEffect(() => {
    setLoading(true);
    getEmailTemplateList()
      .then((res) => {
        setLoading(false);
        setData(res.data);
      })
      .catch((err) => {});
  }, []);
  const deleteTemplate = (Id) => {
    Swal.fire({
      title: "Are you sure?",
      text: "Do you want to delete this template?",
      icon: "warning",
      showDenyButton: true,
      showCancelButton: true,
      showConfirmButton: false,
      denyButtonText: `Delete`,
    }).then((result) => {
      if (result.isDenied) {
        deleteEmailTemplate(Id)
          .then((res) => {
            Swal.fire("Sucessfully deleted", "", "success");
            getEmailTemplateList()
              .then((res) => {
                setLoading(false);
                setData(res.data);
              })
              .catch((err) => {});
          })
          .catch((err) => {});
      }
    });
  };

  return (
    <>
      <div className="d-flex" style={{ padding: "10px 0" }}>
        <div className="container-fluid">
          <Header title="Email template" />

          {/* <div className="subscriptionHeader">
            <div className="d-flex justify-content-end align-items-center">
              <div className="btn-div-width">
                <Link
                  type="button"
                  className="btn btn-lg w-100 font-poppins-medium align-center addSubscriptionbtn"
                  style={{
                    borderRadius: "5px",
                    borderColor: "#6300B0",
                    background: "#6300B0",
                    fontFamily: "Poppins",
                    fontStyle: "normal",
                    color: "#FFF",
                    fontWeight: "500",
                    fontSize: "12px",
                    padding: "10px 20px",
                  }}
                  to={{
                    pathname: "/email-template/Create-email-template",
                  }}
                >
                  <span>+ New Template</span>
                </Link>
              </div>
            </div>
          </div> */}

          {loading ? (
            <>
              <div className="d-flex justify-content-center text-center loaderDiv">
                <ClockLoader color="#6400B0" speedMultiplier={3} />
                <span className="loading">Loading....</span>
              </div>
            </>
          ) : (
            <>
              {data == null || data.length === 0 ? (
                <>
                  <h5 className="text-secondary d-flex justify-content-center mt-5 font-poppins-medium">
                    No result available
                  </h5>
                </>
              ) : (
                <>
                  <div className="table-responsive">
                    <table
                      className="table"
                      style={{
                        borderBottomWidth: "0px",
                        borderBottomColor: "none",
                      }}
                    >
                      <thead>
                        <tr>
                          <th className="tableHead">Template Type</th>
                          <th className="tableHead">Subject</th>
                          <th className="tableHead  text-center">Actions</th>
                        </tr>
                      </thead>
                      <tbody>
                        {data.map((res, i) => (
                          <tr
                            className=""
                            style={{ borderRadius: "30px" }}
                            key={i}
                          >
                            <td className="tableCell front-radius">
                              <div className="mainDiv  d-flex">{res.Name}</div>
                            </td>
                            <td className="tableCell">
                              <div className="mainDiv">{res.Subject}</div>
                            </td>
                            <td className="tableCell  back-radius">
                              <div className="mainDiv justify-content-center d-flex align-items-center">
                                <Link
                                  to={`/email-template/edit-email-template?template_id=${res.Id}`}
                                >
                                  <img src={EditIcon} alt="edit" />
                                </Link>
                              </div>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </>
              )}
            </>
          )}
        </div>
      </div>
    </>
  );
}
