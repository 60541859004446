import axios from "axios";
import store from "../store";
import jwtDecode from "jwt-decode";

const API_URL = process.env.REACT_APP_API_URL || "api";


axios.interceptors.request.use(
  (config) => {
    let token = store.getState().auth.token;
    if (token) {
      if (checkJWTToken(token)) {
        if (token) {
          config.headers.Authorization = `Bearer ${token}`;
        }
        return config;
      }
    } else {
      return config;
    }
  },
  (error) => {
    Promise.reject(error);
  }
);

export function checkJWTToken(Token) {
  const token = Token;
  if (!token) {
    return false;
  } else {
    const decodedToken = jwtDecode(token);
    const expiryTime = decodedToken.exp;
    if (expiryTime * 1000 < Date.now()) {
      return false;
    } else {
      return true;
    }
  }
  return true;
}

export function login(data) {
  return axios.post(`${API_URL}/api/Auth/SuperAdminLogin`, data);
}

// export function login(data) {
//   return axios.post(`${API_URL}/api/Auth/loginUser`, data);
// }

export function register(data) {
  return axios.post(`${API_URL}/api/Auth/SignUp`, data);
}

export function resend(data) {
  return axios.post(`${API_URL}/api/Auth/ResendSignUpMail`, data);
}

export function userVerification(data) {
  return axios.post(`${API_URL}/api/Auth/UserVerification`, data);
}

export function forgotPassword(data) {
  return axios.post(`${API_URL}/api/Auth/forgotPassword`, data);
}

export function updatePassword(data) {
  return axios.post(`${API_URL}/api/Auth/UpdateForgotPassword`, data);
}
export function changePassword(formData) {
  return axios.post(`${API_URL}/api/UserProfile/UpdateUserPassword`, formData);
}

export function verifyEmailForgetPass(data) {
  return axios.post(`${API_URL}/admin/`, data);
}



export function getUserProfile(tenantname, email) {
  return axios.get(
    `${API_URL}/api/UserProfile/GetUserProfile?tenantname=${tenantname}&email=${email}`
  );
}

export function updateUserProfile(profileData) {
  return axios.post(
    `${API_URL}/api/UserProfile/UpdateUserProfile`,
    profileData
  );
}

export function uploadProfileLogo(formData) {
  return axios.post(
    `${API_URL}/api/UserProfile/UpdateUserProfileLogo`,
    formData
  );
}

// ---------------------------- Super Admin Dashboard----------------------------------

export function superDashboard(data) {
  return axios.get(
    `${API_URL}/api/Dashboard/SuperAdminDashBoard?sortOrder=${data.ascdsc}&sortColumn=${data.key}&PageNumber=${data.pageNumber}&PageSize=${data.PageSize}&searchValue=${data.searchname}`
  );
}

export function subscriptonPlans() {
  return axios.get(`${API_URL}/api/Subscription/GetSubscriptionPlans`);
}

export function subscriptonPlansById(id) {
  return axios.get(`${API_URL}/api/Subscription/GetSubscriptionPlanById/${id}`);
}

export function getPermissions() {
  return axios.get(`${API_URL}/api/Subscription/GetAllPermission`);
}

export function postPlan(data) {
  return axios.post(`${API_URL}/api/Subscription/PostSubscriptionPlan`, data);
}

export function putPlan(data) {
  return axios.put(`${API_URL}/api/Subscription/PutSubscriptionPlan`, data);
}

export function subscriptonDisable(id) {
  return axios.delete(`${API_URL}/api/Subscription/DisbaleSubscriptionPlan/${id}`);
}

export function subscriptonEnable(id) {
  return axios.delete(`${API_URL}/api/Subscription/EnableSubscriptionPlan/${id}`);
}
// ------------------------------------------------

export function EditEmailTemplateList(
  Id,
  CcEmail,
  BccEmail,
  Subject,
  Body
) {
  return axios.put(
    `${API_URL}/api/EmailTemplate/PutEmailTemplate/${Id}`,
    {
      CcEmail,
      BccEmail,
      Subject,
      Body,
    }
  );
}

export function getEmailTemplateList() {
  return axios.get(`${API_URL}/api/EmailTemplate/GetAllEmailTemplates`);
}

export function GetEmailTemplateDetail(list_id) {
  return axios.get(
    `${API_URL}/api/EmailTemplate/GetEmailTemplate?id=${list_id}`
  );
}

export function deleteEmailTemplate(id) {
  return axios.delete(
    `${API_URL}/api/EmailTemplate/DeleteEmailTemplate?id=${id}`
  );
}


// ------------------------------------------------------------------------------------

export function getDesktopApplication(data) {
  return axios.get(
    `${API_URL}/api/DesktopDownload/GetDesktopApplicationVersions?sortOrder=${data.sortOrdr}&searchValue=${data.searchname}&sortCoulumn=${data.colName}&PageNumber=${data.pageNumber}&PageSize=10`
  );
}

export function enableApplication(version) {
  return axios.put(
    `${API_URL}/api/DesktopDownload/EnableDesktopApplicationVersion?version_Id=${version}`
  );
}

export function disableApplication(version) {
  return axios.put(
    `${API_URL}/api/DesktopDownload/DisableDesktopApplicationVersion?version_Id=${version}`
  );
}

export function uploadDesktopApplication(data) {
  return axios.post(
    `${API_URL}/api/DesktopDownload/UploadDesktopApplication`,
    data
  );
}

export function applicationByVersionId(version) {
  return axios.get(
    `${API_URL}/api/DesktopDownload/GetDesktopApplicationByVersionId?version_Id=${version}`
  );
}

export function blockUnblockCompany(tenantName, status) {
  return axios.delete(
    `${API_URL}/api/Dashboard/BlockCompanyByTenantName?tenantName=${tenantName}&status=${status}`
  );
}

export function blockUnblockApplication(id, status) {
  return axios.delete(
    `${API_URL}/api/DesktopDownload/EnableDisableDesktopApplicationVersion?version_Id=${id}&status=${status}`
  );
}

