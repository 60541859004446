import React, { useState } from "react";
import eyeSlashIcon from "../Images/formImages/eye-slash-icon.svg";
import eyeShowIcon from "../Images/formImages/eye-icon.svg";
import "./ProfilePage.css";
import * as Yup from 'yup';
import { useFormik } from 'formik';
import { changePassword } from "../authApi/AuthCRUD";
import { shallowEqual, useSelector } from "react-redux";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const passwordFormValidationSchema = Yup.object().shape({
    OldPassword: Yup.string()
        // .min(8, 'Minimum 8 characters is required')
        // .max(20, 'Maximum 20 characters is required')
        .trim()
        .required('Current password is required'),
    NewPassword: Yup.string()
        .min(8, 'Minimum 8 characters is required')
        .max(20, 'Maximum 20 characters is required')
        .matches(/[0-9]/, 'Password requires a number')
        .matches(/[a-z]/, 'Password requires a lowercase letter')
        .matches(/[A-Z]/, 'Password requires an uppercase letter')
        .matches(/[~`!.#%&*_+@?^${}()|[\]\\]/, 'Password requires an one special case character')
        .trim()
        .required('New password is required'),
    ConfirmPassword: Yup.string()
        .min(8, 'Minimum 8 characters is required')
        .max(20, 'Maximum 20 characters is required')
        .matches(/[0-9]/, 'Password requires a number')
        .matches(/[a-z]/, 'Password requires a lowercase letter')
        .matches(/[A-Z]/, 'Password requires an uppercase letter')
        .matches(/[~`!.#%&*_+@?^${}()|[\]\\]/, 'Password requires an one special case character')
        .trim()
        .required('Confirm password is required')
        .oneOf([Yup.ref('NewPassword'), null], 'New password and confirm password must match'),
})
export default function ChangePassword() {
    let Authentication = useSelector((store) => store.auth.data, shallowEqual);
    const tenantName = Authentication.Tenant;
    const email = Authentication.Email;
    const [isPwdActive, setIsPwdActive] = useState(false);
    const [disable, setDisable] = useState(false);
    const [loading, setLoading] = useState(false)
    const [currentPasswordShown, setCurrentPasswordShown] = useState(false);
    const [newPasswordShown, setNewPasswordShown] = useState(false);
    const [confirmPasswordShown, setConfirmPasswordShown] = useState(false);
    const [urlResponseMsg, setUrlResponseMsg] = useState(false)

    const toggleCurrentPasswordVisiblity = (e) => {
        e.preventDefault();
        setCurrentPasswordShown(currentPasswordShown ? false : true);
    };
    const toggleNewPasswordVisiblity = (e) => {
        e.preventDefault();
        setNewPasswordShown(newPasswordShown ? false : true);
    };
    const toggleConfirmPasswordVisiblity = (e) => {
        e.preventDefault();
        setConfirmPasswordShown(confirmPasswordShown ? false : true);
    };
    const formik = useFormik({
        initialValues: {
            Email: email,
            OldPassword: "",
            NewPassword: "",
            // ConfirmPassword: "",
            TenantName: tenantName,
        },
        validationSchema: passwordFormValidationSchema,
        onSubmit: (values, { setStatus, resetForm, setSubmitting, setErrors }) => {
            let data = {
                Email: email,
                OldPassword: values.OldPassword,
                NewPassword: values.NewPassword,
                TenantName: tenantName,
            }
            setLoading(true)
            setDisable(true);
            setUrlResponseMsg(true);
            setTimeout(() => {
                changePassword(data)
                    .then((res) => {
                        setLoading(false)
                        setDisable(false);
                        toast.success(res.data.Message, {
                            position: "top-right",
                            autoClose: 3000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: false,
                            progress: undefined,
                        })
                        resetForm();
                        setIsPwdActive(false)
                        setSubmitting(false);
                    }).catch((err) => {
                        setLoading(false)
                        toast.error(err.response.data.message, {
                            position: "top-right",
                            autoClose: 3000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: false,
                            progress: undefined,
                        });
                        setDisable(false);
                        setStatus(err.response.data.message)
                    })
            }, 1000)
        },
    })
    return (
        <>
            <div id="card-part-user" className="card display-profile-card mt-4 mb-4">
                <div className="card-header cursor-pointer bg-white d-none d-md-block d-lg-block p-4">
                    <div className='card-title m-0 d-flex justify-content-between'>
                        <h3 className='font-poppins-bold m-0 profile-details-heading'>Change Password</h3>
                    </div>
                </div>
                <div className="mt-3 mb-3 d-flex justify-content-between px-2 px-md-4 px-lg-4 align-items-center">
                    {!isPwdActive ?
                        (<>
                            <div>
                                <div className='fs-6 font-poppins-semi-bold mb-1'>Password</div>
                                <div className='font-poppins-medium text-gray-600'>**********</div>
                            </div>
                            <div>
                                <button
                                    className='btn change-pwd-btn text-white font-poppins-medium'
                                    onClick={() => { setIsPwdActive(true); }}
                                >
                                    Change Password
                                </button>
                            </div>
                        </>)
                        :
                        (<>
                            <div className="col-sm-12 col-md-12 col-lg-12 col-xl-10">
                                <form
                                    onSubmit={formik.handleSubmit}
                                    id='kt_signin_change_password'
                                    className='form'
                                    noValidate
                                >
                                    {/* {formik.status ? (
                                        <div className='mb-lg-15 text-danger'>
                                            <div className='mt-3 mb-3 font-weight-bold'>{formik.status}</div>
                                        </div>
                                    ) : ( */}
                                        <div className='bg-light-info mt-2 mb-4 rounded'>
                                            <div className='mont-font-regular' style={{ color: "#6414b5" }}>
                                                Use <strong>current password</strong> to
                                                continue.
                                            </div>
                                        </div>
                                    {/* )} */}
                                    <div className='row mb-1 mt-3'>
                                        <div className='col-md-4 col-lg-4'>
                                            <div className='fv-row mb-0'>
                                                <label htmlFor='currentpassword' className='form-label fs-6 w-100 label-field font-poppins-medium'>
                                                    Current Password
                                                    <input
                                                        type={currentPasswordShown ? "text" : "password"}
                                                        className='form-control mt-2 form-control-solid form-input-field'
                                                        id='project-form-input-tag'
                                                        {...formik.getFieldProps('OldPassword')}
                                                        autoComplete="current-password"
                                                    />
                                                    {currentPasswordShown ?
                                                        <i onClick={toggleCurrentPasswordVisiblity} role="button">
                                                            <img className="eye-icon" src={eyeShowIcon} alt="eyeicon" />
                                                        </i> :
                                                        <i onClick={toggleCurrentPasswordVisiblity} role="button">
                                                            <img className="eye-icon" src={eyeSlashIcon} alt="eyeicon" />
                                                        </i>
                                                    }
                                                    {formik.touched.OldPassword && formik.errors.OldPassword && (
                                                        <div className='font-poppins-medium'>
                                                            <div className='mb-1 formErr'>{formik.errors.OldPassword}</div>
                                                        </div>
                                                    )}
                                                </label>
                                            </div>
                                        </div>

                                        <div className='col-md-4 col-lg-4'>
                                            <div className='fv-row mb-0'>
                                                <label htmlFor='newpassword' className='form-label fs-6 w-100 label-field font-poppins-medium'>
                                                    New Password
                                                    <input
                                                        type={newPasswordShown ? "text" : "password"}
                                                        autoComplete="new-password"
                                                        className='form-control form-control-solid mt-2 form-input-field'
                                                        id='project-form-input-tag'
                                                        {...formik.getFieldProps('NewPassword')}
                                                        title="Make sure password must have at least 8 characters including an uppercase letter,
                                                        a lowercase letter, 
                                                        a special character and number."
                                                    />
                                                    {newPasswordShown ?
                                                        <i onClick={toggleNewPasswordVisiblity} role="button">
                                                            <img className="eye-icon" src={eyeShowIcon} alt="eyeicon" />
                                                        </i>
                                                        :
                                                        <i onClick={toggleNewPasswordVisiblity} role="button">
                                                            <img className="eye-icon" src={eyeSlashIcon} alt="eyeicon" />
                                                        </i>}
                                                    {formik.touched.NewPassword && formik.errors.NewPassword && (
                                                        <div className='font-poppins-medium'>
                                                            <div className='mb-1 formErr'>{formik.errors.NewPassword}</div>
                                                        </div>
                                                    )}
                                                </label>
                                            </div>
                                        </div>

                                        <div className='col-md-4 col-lg-4'>
                                            <div className='fv-row mb-0'>
                                                <label htmlFor='confirmpassword' className='form-label fs-6 w-100 label-field font-poppins-medium'>
                                                    Confirm Password
                                                    <input
                                                        type={confirmPasswordShown ? "text" : "password"}
                                                        autoComplete="confirm-password"
                                                        className='form-control form-control-solid mt-2 form-input-field'
                                                        id='project-form-input-tag'
                                                        {...formik.getFieldProps('ConfirmPassword')}
                                                        title="Make sure confirm password must match with new password and follow the requested pattern."
                                                    />
                                                    {confirmPasswordShown ?
                                                        <i onClick={toggleConfirmPasswordVisiblity} role="button">
                                                            <img className="eye-icon" src={eyeShowIcon} alt="eyeicon" />
                                                        </i>
                                                        :
                                                        <i onClick={toggleConfirmPasswordVisiblity} role="button">
                                                            <img className="eye-icon" src={eyeSlashIcon} alt="eyeicon" />
                                                        </i>}
                                                    {formik.touched.ConfirmPassword && formik.errors.ConfirmPassword && (
                                                        <div className='font-poppins-medium'>
                                                            <div className='mb-1 formErr'>{formik.errors.ConfirmPassword}</div>
                                                        </div>
                                                    )}
                                                </label>
                                            </div>
                                        </div>
                                    </div>

                                    <div className='d-flex mt-4'>
                                        <button
                                            disabled={disable}
                                            type='submit'
                                            className='btn me-2 submit_btn save-changes-button save-btn text-white mont-font-regular'
                                        >
                                            {!loading && 'Submit'}
                                            {loading && (
                                                <span className='indicator-progress' style={{ display: 'block' }}>
                                                    Please wait...{' '}
                                                    <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                                                </span>
                                            )}
                                        </button>
                                        <div
                                        >
                                            <button
                                                onClick={(e) => {
                                                    e.preventDefault();
                                                    formik.handleReset();
                                                    setIsPwdActive(false)
                                                }}
                                                type='button'
                                                className='btn cancel-leave-btn mont-font-regular'
                                            >Cancel
                                            </button>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </>)}
                </div>
            </div>
            {urlResponseMsg ? (<>
                <ToastContainer />
            </>) : (null)}
        </>
    )
}