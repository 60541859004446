import React, { useEffect, useState } from "react";
import "../css/style.css";
import { useNavigate, useLocation } from "react-router-dom";
import styled from "styled-components";
import { Header } from "../layoutComponent/Header";
import FormGroup from "@mui/material/FormGroup";
import moment from "moment";
import backArrow from "../Images/livedashboard/back-arrow.svg";

import { applicationByVersionId } from "../authApi/AuthCRUD";

export default function ViewUpload() {
  const styles = {
    select: {
      fontFamily: "Poppins",
      fontStyle: "normal",
      fontWeight: "400",
      fontSize: "13px",
      lineHeight: "20px",
      color: "#B6B6B6",
    },
    cancelBtn: {
      border: "none",
      marginRight: "5px",
      borderRadius: "60px",
      background: "#616773",
      color: "#fff",
      padding: "10px 100px",
    },
    saveBtn: {
      border: "none",
      borderRadius: "30px",
      padding: "10px 100px",
      background: "#6414b5",
      color: "#fff",
    },
  };
  const Label = styled.label`
    font-family: "Poppins";
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    line-height: 20px;
    color: #616773;
  `;
  const Labels = styled.label`
    font-family: "Poppins";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    color: #616773;
  `;

  const { search } = useLocation();
  let version_Id = new URLSearchParams(search).get("id");

  const [version, setVersionName] = useState("");
  const [file, setFile] = useState("");
  const [uploadDate, setUploadDate] = useState("");
  const navigate = useNavigate();

  const uploadData = () => {
    applicationByVersionId(version_Id)
      .then((res) => {
        setFile(res.data.Data.Uploaded_File);
        setVersionName(res.data.Data.Version_No);
        setUploadDate(res.data.Data.Uploaded_Date);
      })
      .catch((err) => {
        console.log(err, "error");
      });
  };

  useEffect(() => {
    uploadData();
  }, []);

  return (
    <React.Fragment>
      <section className="d-flex" style={{ padding: "10px 0" }}>
        <div className="container-fluid">
          <Header title="View Desktop Application" />
          <div className="text-start mt-3 mb-3">
            <div
            className="d-flex align-items-center"
            role="button"
            onClick={() => navigate(-1)}
            >
              <i><img src={backArrow} alt="back-Arrow" /></i>
              <span className="font-poppins-regular backBtn">&nbsp;&nbsp;&nbsp;Back</span>
            </div>
          </div>
          <FormGroup>
            <section className="addUsers" style={{ padding: "30px 50px" }}>
              <div className="container-fluid">
                <form>
                  {/* <div className="heading">View Desktop Application</div> */}
                  <div className="row">
                    <div className="col-lg-6 col-md-12 col-sm-12">
                      <div className="form-group mt-3">
                        <Label>Version Number</Label>
                        <input
                          type="text"
                          name="Version_Number"
                          value={"Odyssey " + version}
                          className="form-control bdr-radius team-select"
                        />
                      </div>
                    </div>
                  </div>

                  <div className="row mt-3">
                    <div className="col-lg-12 col-md-12 col-sm-12">
                      <div className="form-group mt-2">
                        <Labels>Your File Url :</Labels>
                        <a href={file}>{file}</a>
                      </div>
                    </div>
                  </div>

                  <div className="row mt-3">
                    <div className="col-lg-12 col-md-12 col-sm-12">
                      <div className="form-group mt-2">
                        <Labels>Application Uploaded Date :</Labels>&nbsp;
                        <Labels>
                          {moment(uploadDate).format("DD.MM.YYYY")}
                        </Labels>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </section>
          </FormGroup>
        </div>
      </section>
    </React.Fragment>
  );
}
