import React, { useEffect, useState } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { useDispatch, shallowEqual, useSelector } from "react-redux";
import { authUserLogout } from "../reduxAuthentication/actions";
import { checkUserPermissions } from "../authApi/AuthCRUD";
import * as AiIcons from "react-icons/ai";
import * as CgIcons from "react-icons/cg";
import * as FaIcons from "react-icons/fa";
import * as IoIcons from "react-icons/io";
import * as RiIcons from "react-icons/ri";
import * as IconName from "react-icons/bs";
import profile from "../Images/profile.svg";
import Dropdown from "react-bootstrap/Dropdown";
import ProfileIamge from "../Images/profileimage.png";
import settings from "../Images/settingsico.svg";
import logouticon from "../Images/livedashboard/logout.svg";
import styled from "styled-components";
import Logo from "../Images/odysseylogo.png";

import "../css/style.css";

const SidebarSpace = styled.div`
  background: #fff;
  padding-bottom: 10px;
`;
const ProfileContent = styled.div`
  text-align: center;
  margin: 20px 5px;
`;
const LogoContent = styled.div`
  padding: 14px 0;
  display: flex;
  justify-content: center;
  align-items: center;
`;
const LogoName = styled.div`
  font-weight: 600;
  font-size: 25px;
  margin-left: 5px;
`;
const ProfileName = styled.div`
  font-weight: 600;
  font-size: 20px;
`;
const ProfileEmail = styled.div`
  font-size: 13px;
  color: gray;
`;
const CustomDown = React.forwardRef(({ children, onClick }, ref) => (
  <a
    href=""
    ref={ref}
    onClick={(e) => {
      e.preventDefault();
      onClick(e);
    }}
  >
    {children}
    <span className="ProfileContent">
      <img
        src={ProfileIamge}
        alt="ProfileIamge"
        style={{ width: "35px", height: "35px" }}
      />
    </span>
  </a>
));
const Header = ({ title }) => {
  let Detail = useSelector((store) => store.auth.data, shallowEqual);
  const storedImg = useSelector(
    (store) => store.auth.userProfile,
    shallowEqual
  );
  const [storedProfileImage, setStoredProfileImage] = useState("");
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const UserRole = Detail.User_Role;

  const location = useLocation();
  var getlocation = location.pathname;
  var UserName = localStorage.getItem("FullName");
  var UserEmail = localStorage.getItem("Email");
  let fullName = [];
  let initials = "";
  fullName = Detail.FullName.split(" ");
  if (fullName.length === 2) {
    initials = fullName.shift().charAt(0) + fullName.pop().charAt(0);
  } else {
    initials = fullName.shift().charAt(0);
  }
  useEffect(() => {
    setStoredProfileImage(storedImg);
  }, [storedImg]);
  const logOut = () => {
    localStorage.clear();
    dispatch(authUserLogout());
    navigate("/");
  };
  var a = window.location.href;
  var result = a.slice(22);
  localStorage.setItem("WindowLocation", result);
  const [open, setOpen] = React.useState(false);
  const [adminSelectOpen, setAdminSelectOpen] = React.useState(false);
  let Authentication = useSelector((store) => store.auth.data, shallowEqual);

  useEffect(() => {
    setStoredProfileImage(storedImg);
  }, [storedImg]);
  const handleClick = () => {
    setOpen(!open);
  };
  const handleAdminSelectClick = () => {
    setAdminSelectOpen(!adminSelectOpen);
  };

  return (
    <>
      <div className="row m-0 mt-2">
        <nav className="navbar navbar-expand-lg">
          <div className="container-fluid p-0 p-md-auto p-lg-auto">
            <button
              className="sm-navbar navbar-toggler"
              type="button"
              data-bs-toggle="offcanvas"
              data-bs-target="#smallScreenSideBar"
              aria-controls="smallScreenSideBar"
            >
              <CgIcons.CgMenuRight
                style={{ color: "white", fontSize: "25px" }}
              />
            </button>
            <span className="navbar-brand font-poppins-semi-bold">{title}</span>
            <div className="m-auto m-md-0 navbarImg">
              <form className="d-flex justify-content-center align-items-center ms-auto">
                <div className=" ms-2">
                  <Dropdown className="profile-dropdown">
                    {/* <Dropdown.Toggle as={CustomDown} /> */}
                    <Dropdown.Toggle className="profile-bg">
                      {storedProfileImage && storedProfileImage != "null" ? (
                        <>
                          <div
                            role="button"
                            id="dropdownMenuLink"
                            data-bs-toggle="dropdown"
                            aria-expanded="false"
                          >
                            <img
                              src={storedProfileImage}
                              alt="ProfileIamge"
                              style={{
                                width: "50px",
                                height: "50px",
                                borderRadius: "50%",
                                objectFit: "cover",
                              }}
                            />
                          </div>
                        </>
                      ) : (
                        <>
                          <div
                            className="rounded-circle d-flex justify-content-center"
                            style={{
                              width: " 50px",
                              height: "50px",
                              background: "#fff",
                            }}
                            role="button"
                            id="dropdownMenuLink"
                            data-bs-toggle="dropdown"
                            aria-expanded="false"
                          >
                            <div className="d-flex align-items-center text-uppercase header-initials-text fs-4">
                              {initials}
                            </div>
                          </div>
                        </>
                      )}
                    </Dropdown.Toggle>
                    <Dropdown.Menu size="lg" title="">
                      <Dropdown.Item className="drpdwnitem">
                        <Link
                          to={{
                            pathname: "/company-settings",
                          }}
                        >
                          <div className="d-flex">
                            <img
                              src={settings}
                              alt="icon"
                              width={15}
                              height={17}
                            />
                            <div> &nbsp; Settings</div>{" "}
                          </div>
                        </Link>
                      </Dropdown.Item>
                      <Dropdown.Item className="drpdwnitem">
                        <Link
                          to={{
                            pathname: "/profile-page",
                          }}
                        >
                          <div
                            className="d-flex"
                            style={{ position: "relative", right: "1px" }}
                          >
                            <img
                              src={profile}
                              alt="icon"
                              width={16}
                              height={17}
                            />
                            <div> &nbsp; View Profile</div>{" "}
                          </div>
                        </Link>
                      </Dropdown.Item>
                      <Dropdown.Item className="drpdwnitem">
                        <div className="d-flex" onClick={logOut}>
                          <img
                            src={logouticon}
                            alt="icon"
                            width={15}
                            height={17}
                          />
                          <div> &nbsp; Logout</div>
                        </div>
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                </div>
              </form>
            </div>
          </div>
        </nav>
        {/* begin::Small screen sidebar code */}
        <div
          className="offcanvas offcanvas-start manage-backdrop p-0 d-lg-none"
          data-bs-scroll="true"
          style={{ width: "267px" }}
          tabIndex={-1}
          id="smallScreenSideBar"
          aria-labelledby="smallScreenSideBarLabel"
          data-backdrop="true"
        >
          <SidebarSpace
            className="h-100 overflow-auto bg-light flex-column sticky-top pl-0"
            id="custom-scroll"
          >
            <LogoContent>
              <div className="logoImage">
                <img
                  data-cke-saved-src={Logo}
                  src={Logo}
                  alt="Logo"
                  width={60}
                />
              </div>
              <LogoName>Odyssey</LogoName>
            </LogoContent>
            <hr style={{ margin: "0", height: "3px", color: "#CCCCCC" }} />
            <ProfileContent>
              <div className="ProfileContent">
                {storedProfileImage && storedProfileImage != "null" ? (
                  <>
                    <img
                      src={storedProfileImage}
                      alt="ProfileIamge"
                      className="ProfileIamge"
                    />
                  </>
                ) : (
                  <>
                    <div
                      className="rounded-circle mb-3 d-flex justify-content-center ProfileIamge"
                      style={{
                        width: " 70px",
                        height: "70px",
                        background:
                          "linear-gradient(264.71deg, #8F009B 18.58%, #420085 128.77%)",
                      }}
                    >
                      <div className="d-flex align-items-center text-white fs-3 text-uppercase">
                        {initials}
                      </div>
                    </div>
                  </>
                )}
                <div className="sidebar-user-box mt-2">
                  <h5 className="font-poppins-medium">{Detail.FullName}</h5>
                  <span className="font-poppins-regular">{Detail.Email}</span>
                </div>
              </div>
              <ProfileName>{UserName}</ProfileName>
              <ProfileEmail>{UserEmail}</ProfileEmail>
            </ProfileContent>
            <hr style={{ margin: "0", height: "3px", color: "#CCCCCC" }} />
            <div className="sideNav ">
              <div className="menuContent mt-3">
                <ul>
                  <li
                    className={`${
                      getlocation === "/superadmindashboard" ? "active" : ""
                    }`}
                  >
                    <Link to="/superadmindashboard" className="nav-items-link">
                      <div>
                        <AiIcons.AiFillHome className="icon" />
                      </div>
                      <div className="item">Dashboard</div>
                    </Link>
                  </li>
                  <li
                    className={`${
                      getlocation === "/superadminsubscription" ? "active" : ""
                    }`}
                  >
                    <Link
                      to="/superadminsubscription"
                      className="nav-items-link"
                    >
                      <div>
                        <AiIcons.AiFillHome className="icon" />
                      </div>
                      <div className="item">Subscription</div>
                    </Link>
                  </li>
                  <li
                    className={`${
                      getlocation === "/upload-version-list"
                        ? "active"
                        : getlocation === "/upload-app"
                        ? "activeDropClass"
                        : getlocation === "/update-app"
                        ? "activeDropClass"
                        : getlocation === "/upload-app-details"
                        ? "activeDropClass"
                        : ""
                    }`}
                  >
                    <Link to="/upload-version-list" className="nav-items-link">
                      <div>
                        <AiIcons.AiOutlineUpload className="icon" />
                      </div>
                      <div className="item">Upload</div>
                    </Link>
                  </li>

                  <li
                    className={`${
                      getlocation === "/email-template"
                        ? "active"
                        : getlocation === "/edit-email-template"
                        ? "activeDropClass"
                        : ""
                    }`}
                  >
                    <Link to="/email-template" className="nav-items-link">
                      <div>
                        <FaIcons.FaEnvelope className="icon" />
                      </div>
                      <div className="item">Email Templates</div>
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
          </SidebarSpace>
        </div>
        {/* end::Small screen sidebar code */}
      </div>
    </>
  );
};
export { Header };
