import React, { useEffect, useState } from "react";
import "../css/style.css";
import { Link, useNavigate } from "react-router-dom";
import ReactPaginate from "react-paginate";
import "font-awesome/css/font-awesome.min.css";
import { TableHead } from "@mui/material";
import Dropdown from "react-bootstrap/Dropdown";
import edit from "../Images/edit.svg";
import enableIcon from "../Images/livedashboard/enable-icon.svg";
import disableIcon from "../Images/livedashboard/disable-icon.svg";
import view from "../Images/livedashboard/view.svg";
import {
  getDesktopApplication,
  disableApplication,
  enableApplication,
  blockUnblockApplication,
} from "../authApi/AuthCRUD";
import { Header } from "../layoutComponent/Header";
import backArrow from "../Images/livedashboard/back-arrow.svg";
import moment from "moment";
import sorticon from "../Images/sorticon.svg";

const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
  <a
    href=""
    ref={ref}
    onClick={(e) => {
      e.preventDefault();
      onClick(e);
    }}
  >
    {children}
    <span className="threedots" />
  </a>
));

const Upload = () => {
  let [data, setData] = useState([]);
  let [currentpage, setCurrentPage] = useState(1);
  let [flag, setflag] = useState(false);
  const navigate = useNavigate();
  const [noOfPage, setNoOfPage] = useState(1);

  useEffect(() => {
    getData();
  }, []);

  const getData = (
    check = true,
    search = "",
    colName = "Id",
    pageNo = currentpage || 1
  ) => {
    let data = {
      sortOrdr: check,
      searchname: search,
      colName: colName,
      pageNumber: pageNo,
    };
    getDesktopApplication(data)
      .then((response) => {
        setData(response.data.Data);
        setNoOfPage(response.data.TotalPages);
      })
      .catch((err) => {
        console.log(err, "error");
      });
  };

  const handlePageClick = (data) => {
    let pageNumber = data.selected + 1;
    setCurrentPage(pageNumber);
    getData(true, "", "Id", pageNumber);
  };

  const searchHandler = (e) => {
    if (e.target.value == "") {
      getData();
    } else {
      getData(flag, e.target.value, "Id", 1);
    }
  };

  const onSort = (key, check) => {
    if (check) setflag(false);
    else setflag(true);
    getData(flag, "", key, 1);
  };

  //disableApplication   //enableApplication
  const blockUnblockApplications = (id, status) => {
    blockUnblockApplication(id, status)
      .then((res) => {
        console.log(res);
        getData();
      })
      .catch((err) => {
        console.log(err, "error");
      });
  };

  return (
    <section className="d-flex" style={{ padding: "10px 0" }}>
      <div className="container-fluid">
        <Header title="Version History" />

        <div className="mt-4 mb-4">
          <div className="row">
            <div className="col-sm-12 col-md-6 col-lg-6 d-flex">
              <div className="sb-example-3 w-100">
                <div className="search__container">
                  <input
                    className="search-input-field font-poppins-regular"
                    type="text"
                    placeholder="Search Version..."
                    autoComplete="on"
                    onKeyUp={searchHandler}
                  />
                </div>
              </div>
            </div>
            <div className="col-sm-12 col-md-6 col-lg-6">
              <div className="d-flex justify-content-lg-end justify-content-md-end justify-content-center mt-2 mt-md-0 mt-lg-0">
                <div className="btn-div-width">
                  <Link
                    type="button"
                    className="btn btn-lg w-100 font-poppins-medium align-center text-white"
                    style={{
                      borderRadius: "5px",
                      borderColor: "#6300B0",
                      background: "#6300B0",
                      fontFamily: "Poppins",
                      fontStyle: "normal",
                      color: "#FFF !important",
                      fontWeight: "500",
                      fontSize: "12px",
                      padding: "10px 20px",
                    }}
                    to={{
                      pathname: "/upload-app",
                    }}
                  >
                    <span>+ Upload Desktop App</span>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
        <hr />
        {!data || data.length === 0 ? (
          <>
            <h5 className="text-secondary d-flex justify-content-center mt-5 font-poppins-medium">
              No result available
            </h5>
          </>
        ) : (
          <>
            <div className="table-responsive">
              <table
                className="table"
                style={{
                  borderBottomWidth: "0px",
                  borderBottomColor: "none",
                }}
              >
                <thead className="adminTable">
                  <tr>
                    <th className="tableHead firstHead">
                      Application Version &nbsp;
                      <button
                        onClick={() => {
                          onSort("VersionNo", flag);
                        }}
                        type="button"
                      >
                        <img src={sorticon} alt="icon" />
                      </button>
                    </th>
                    <th className="tableHead text-center">
                      Uploaded Date
                      <button
                        onClick={() => {
                          onSort("UploadedDate", flag);
                        }}
                        type="button"
                      >
                        <img src={sorticon} alt="icon" />
                      </button>
                    </th>
                    {/* <th></th> */}
                  </tr>
                </thead>
                <tbody>
                  {data.map((res, i) => (
                    <tr className="" style={{ borderRadius: "30px" }} key={i}>
                      <td
                        className={
                          res.IsEnable
                            ? "tableCell front-radius compName"
                            : "cellInactive front-radius compName"
                        }
                      >
                        <div
                          className="maindiv"
                          style={{ marginLeft: "0.25rem" }}
                        >
                          Odyssey {res.VersionNo}
                        </div>
                      </td>
                      <td
                        className={
                          res.IsEnable ? "tableCell " : "cellInactive "
                        }
                      >
                        <div className="maindiv text-center">
                          {res.UploadedDate !== null
                            ? moment(res.UploadedDate).format("DD.MM.YYYY")
                            : "-"}
                        </div>
                      </td>
                      <td
                        className={
                          res.IsEnable
                            ? "tableCell back-radius"
                            : "cellInactive back-radius"
                        }
                      >
                        <Dropdown>
                          <Dropdown.Toggle as={CustomToggle} />
                          <Dropdown.Menu size="sm">
                            <Dropdown.Item
                              className="drpdwnitem"
                              style={{
                                color: "red",
                                opacity: "0.9",
                                fontSize: "12px",
                                fontWeight: "400",
                                fontFamily: "Poppins",
                                fontStyle: "normal",
                              }}
                            >
                              {!res.IsEnable ? (
                                <div
                                  className="d-flex justify-content-center align-items-center"
                                  id="1"
                                  style={{
                                    color: "green",
                                  }}
                                  onClick={() =>
                                    blockUnblockApplications(res.Id, true)
                                  }
                                >
                                  <img
                                    src={enableIcon}
                                    alt="icon"
                                    width={15}
                                    height={15}
                                  />
                                  <div> &nbsp; Enable</div>
                                </div>
                              ) : (
                                <div
                                  className="d-flex justify-content-center align-items-center"
                                  id="2"
                                  style={{
                                    color: "red",
                                  }}
                                  onClick={() =>
                                    blockUnblockApplications(res.Id, false)
                                  }
                                >
                                  <img
                                    src={disableIcon}
                                    alt="icon"
                                    width={15}
                                    height={15}
                                  />
                                  <div> &nbsp; Disable</div>
                                </div>
                              )}
                            </Dropdown.Item>
                          </Dropdown.Menu>
                        </Dropdown>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
            {(noOfPage == 1 && data.length <= 10) || noOfPage == 0 ? (
              ""
            ) : (
              <ReactPaginate
                previousLabel={"<"}
                nextLabel={">"}
                pageCount={noOfPage}
                onPageChange={handlePageClick}
                containerClassName={"pagination justify-content-end"}
                pageClassName={"page-item"}
                pageLinkClassName={"page-link"}
                previousClassName={"page-item"}
                previousLinkClassName={"page-link"}
                nextClassName={"page-item"}
                nextLinkClassName={"page-link"}
                breakClassName={"page-item"}
                breakLinkClassName={"page-link"}
                activeClassName={"active"}
              />
            )}
          </>
        )}
      </div>
    </section>
  );
};

export default Upload;
